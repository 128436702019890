import React, { useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { vsDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { vs, vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  atomOneDark,
  defaultStyle,
  docco,
  github,
  nightOwl,
} from "react-syntax-highlighter/dist/esm/styles/hljs";

const LanguageCode = ({ language }) => {
  console.log(language, "languagePPP");

  const [codeSnippet, setCodeSnippet] = useState("");

  // create a function to copy code
  const copyCodeHandler = () => {
    navigator.clipboard.writeText(codeSnippet);
  };
  console.log(codeSnippet, "Code Snippet");
  // create a function to generate code snippet
  const generateCodeSnippet = (language) => {
    switch (language) {
      case "Javascript":
        const jsCode = `var settings = { 
 'url': 'http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/',
 'method': 'GET',
 'timeout': 0,
 'headers': {
   'Authorization': 'Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30'
  },
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`;

        // Set the codeSnippet state
        setCodeSnippet(jsCode);
        break;
      case "C# - HttpClient":
        const cSharpCode = `var client = new HttpClient();
var request = new HttpRequestMessage(HttpMethod.Get, "http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/");
request.Headers.Add("Authorization", "Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30");
var response = await client.SendAsync(request);
response.EnsureSuccessStatusCode();
Console.WriteLine(await response.Content.ReadAsStringAsync());`;
        setCodeSnippet(cSharpCode);
        break;
      case "curl":
        const curlCode = `curl --location 'http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/'
--header 'Authorization: Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30'`;
        setCodeSnippet(curlCode);
      case "Dart - dio":
        const dartCode = `var headers = {
  'Authorization': 'Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30'
  
   };
   var dio = Dio();
   var response = await dio.get('http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/', options: Options(
      method: 'GET',
      headers: headers,
     ),
    );
    
    if (response.statusCode == 200) {
      print(json.encode(response.data));
    }
    else {
      print(response.statusMessage);
    }`;
        setCodeSnippet(dartCode);
        break;
        case "Dart - http":
          const dartHttp = `var headers = {
    'Authorization':'Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30'    
  };
var request = http.Request('GET', Uri.parse('http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/'));

request.headers.addAll(headers);

http.StreamedResponse response = await request.send();
   
if (response.statusCode == 200) {
  print(await response.stream.bytesToString());
}
else {
  print(response.reasonPhrase);
}`;
          setCodeSnippet(dartHttp);
          break;
          case "Go - Native":
            const goNative = `package main

import (
 "fmt"
 "net/http"
 "io/ioutil"
)
            
func main() {
            
  url := "http://staging-ls1.oneverifi.com:8001/api/business/mfa/verify/"
  method := "GET"
            
  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)
            
  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("Authorization", "Token eb5bfb8bbba65f4fb2c9473040ef9669b1d34f30")
            
  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()
            
  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}`;
            setCodeSnippet(goNative);
            break;
      case "nodejs":
        console.log("nodejs");
        break;
      case "php":
        console.log("php");
        break;
      default:
        console.log("default");
        break;
    }
  };

  useEffect(() => {
    generateCodeSnippet(language);
  }, [language]);

  return (
    <div className="row mt-3">
      <div className="col-md-12">
        <div className="form-group">
          <div className="code_container">
            <div className="code_header">
              <p className="lang_title">{language}</p>
              <p className="lang_title" onClick={copyCodeHandler}>
                Copy
              </p>
            </div>
            {/* Render SyntaxHighlighter with language and codeSnippet */}
            <SyntaxHighlighter
              language={language}
              style={nightOwl}
              customStyle={{ fontSize: "14px" , overflowX: "auto"}}
              wrapLongLines={true}
              showLineNumbers={true}
              wrapLines={true}
              className="py-4"
              lineProps={{
                style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
              }}
              CodeTag="div"
            >
              {codeSnippet}
            </SyntaxHighlighter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageCode;
