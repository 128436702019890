import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { PAGE_LIMIT } from "../../constants";
import { _ } from "../../locale";
import ManagePageSlide from "./ManagePageSlide";
import Refresh from "../../components/common/Refresh";
import ServiceSkelton from "../services/ServiceSkelton";
import { SERVICE_LIST, getServicesListing } from "../../actions/services";
import { KEY_LIST, getKeysListing } from "../../actions/keys";
import ManageKeySlide from "./ManageKeySlide";
import KeySkelton from "../../components/KeySkelton";

function ManageKeys() {
  // local variables
  const dispatch = useDispatch();
  const list = useSelector((state) => state.keys);
  const { fetchKeyList } = list;
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const fetchData = useCallback(async (page) => {
    setShowLoader(true);
    try {
      const resp = await getKeysListing({ page: page });
      dispatch({ type: KEY_LIST, payload: resp?.data?.responseData });
      setShowLoader(false);
    } catch ({ response, request }) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setShowLoader(false);
    }
  }, []);

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  useEffect(() => {
    fetchData(activePage);
  }, [fetchData, activePage, fetchKeyList]);

  let limit = list?.keyListing?.perPage ?? PAGE_LIMIT;

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h3 className="mb-sm-0 page-title">{_("KEY_LISTING")}</h3>
              {/* <div className="flex-shrink-0 gap-2">
                <Link to="/dashboard/key/add" className="btn btn-primary">{_('ADD_KEY')}</Link>
                <Refresh />
            </div> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="custom-table">
              <div className="custom-table-body">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                      <tr>
                        <th scope="col" style={{ width: "70px" }}>
                          #
                        </th>
                        <th scope="col">{_("SERVICE")}</th>
                        <th scope="col">{_("KEY_NAME")}</th>
                        {/* <th scope="col">{_('CREATED_BY')}</th>
                        <th scope="col">{_('UPDATED_BY')}</th> */}
                        <th scope="col">{_("STATUS")}</th>
                        <th scope="col">{_("ACTION")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {showLoader == true ? (
                        <KeySkelton count={5} />
                      ) : list?.keyListing?.data?.length > 0 ? (
                        list &&
                        list?.keyListing?.data?.length > 0 &&
                        list?.keyListing?.data?.map((obj, index) => {
                          console.log(`@@@@`, obj);
                          return (
                            <ManageKeySlide
                              slideData={obj}
                              key={obj.id}
                              index={index}
                              srno={(activePage - 1) * limit}
                            />
                          );
                        })
                      ) : (
                        <tr>
                          <td colspan="8" className="empty-record-msg">
                            <h5 className="text-truncate mb-4 mb-lg-5">
                              No Record exist.{" "}
                              <Link to="/dashboard/page/add">Click here</Link>{" "}
                              to create new question
                            </h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <ResponsivePagination
                current={activePage}
                total={list?.totalPages ?? 1}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageKeys;
