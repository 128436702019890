import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const KeySkelton = ({count}) => {
  const items = new Array(count).fill(0);
  return (
    <>
    {
      items.map((item, index) => (
        <tr>
          <td><p className="font-size-14 mb-1"><Skeleton width={15} height={15} /></p></td>
          <td><Skeleton width={100} height={15} /></td>
          
          <td>
          <td><Skeleton width={250} height={15} /></td>

          
          </td>
          <td>
          <td><Skeleton width={50} height={15} /></td>
         
          </td>

          <td><a href="javascript:void(0)"><Skeleton width={60} height={15} /></a></td>
         
        </tr>
      ))
    }
    </>
  );
};

export default KeySkelton;