import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import Input from "../../components/FormFields/input";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import {
  translateSchema,
  serviceSchema,
  resEveSchema,
  validationSchema,
} from "./schema";
import CustomSelect from "../../components/FormFields/CustomSelect";
import { createPlayground, getVideoService } from "../../actions/keys";
import axios from "axios";
import PreviewSkelton from "../../components/PreviewSkelton";

import Videos from "../../components/JoinCall/Videos";

import {
  AgoraRTCProvider,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useRemoteAudioTracks,
  useRemoteUsers,
  RemoteUser,
  LocalVideoTrack,
  useClientEvent,
} from "agora-rtc-react";
import AgoraRTC, { ILocalAudioTrack, ILocalVideoTrack } from "agora-rtc-sdk-ng";
import AgoraUIKit, { PropsInterface, layout } from "agora-react-uikit";
import AgoraRTM from "agora-rtm-sdk";
function AddPlayground() {
  const [isPinned, setPinned] = useState(false);
  const rtcProps = {};
  const [getRtcProps, setRtcProps] = useState(null);
  const [RtmProps, setRtmProps] = useState(null);

  const { id, dynamic } = useParams();
  const [questionTags, setQuestionTags] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  console.log(previewData, "Idd");
  // for call playgorund
  const client = useRTCClient(
    AgoraRTC.createClient({ codec: "vp8", mode: "rtc" })
  );

  const [inCall, setInCall] = useState(false);
  const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

  // create initial values
  let translateIntialValues = {
    apikey: id,
    text: [
      {
        content: "",
      },
    ],
    sourceLanguage: "",
    targetLanguages: [],
  };
  let serviceIntialValues = {
    apikey: id,
    text: "",
    sourceLanguage: "",
    targetLanguages: [],
  };
  let ResEveInitialValues = {
    apikey: id,
    question: "",
    answer: "",
  };
  let conferenceInitial = {
    apikey: id,
    AppID: "",
    channelName: "",
    userName: "",
  };

  // on form submissions

  const onSubmit = async (values) => {
    console.log(values, "Allert");
    setShowLoader(true);
    let payload;
    let thirdArgument;

    switch (dynamic) {
      case "translation":
        payload = {
          text: values.text?.map((item) => item.content),
          sourceLanguage: values.sourceLanguage,
          targetLanguages: values.targetLanguages,
        };
        thirdArgument = "translate";
        break;
      case "Sentiment Analysis":
        payload = {
          text: values.text,
        };
        thirdArgument = "sentiment - analysis";
        break;
      case "Response evaluation":
        payload = {
          question: values.question,
          answer: values.answer,
        };
        thirdArgument = "response - analysis";
        break;
      case "conference":
        payload = {
          channelName: values.channelName,
          // memberId: values.memberId,
          displayName: values.userName,
          memberId: parseInt(values.AppID, 10),
        };
        console.log(payload, "conference");
        setInCall(true);

        // thirdArguent = "conference";
        break;
    }

    try {
      const createReq = await createPlayground(
        payload,
        values.apikey,
        thirdArgument
      );

      let { data, status } = createReq;
      setShowLoader(false);
      console.log(data?.responseData, "HelloRespinse Up");

      setPreviewData(data?.responseData);

      // toast.success(data?.message);
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setShowLoader(false);
    }

    if (dynamic == "conference") {
      try {
        const getVideoData = await getVideoService(payload, id);
        let { data, status } = getVideoData;
        setShowLoader(false);
        console.log(data?.responseData, "HelloRespinse Down");

        setPreviewData(data?.responseData);
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
        setShowLoader(false);
      }
    }
  };
  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flex: 1,
      backgroundColor: "white",
    },
    btn: {
      backgroundColor: "#007bff",
      cursor: "pointer",
      borderRadius: 5,
      padding: 5,
      color: "#ffffff",
      fontSize: 20,
    },
  };
  console.log(previewData, "inCall");
  const fetchLanguages = async () => {
    try {
      const response = await axios.get(
        "https://api.cognitive.microsofttranslator.com/languages?api-version=3.0"
      );

      const languages = response?.data?.dictionary;

      if (languages) {
        const languageOptions = Object.keys(languages).map((code) => {
          const language = languages[code];
          return {
            value: code,
            label: language.name,
            translations: language.translations,
          };
        });

        setQuestionTags(languageOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  let dynamicContent;
  let previewContent;

  console.log(previewData, "previewDatapreviewData");

  useEffect(() => {
    setRtcProps({
      appId: AGORA_APP_ID,
      channel: previewData?.channelName,
      uid: previewData?.memberId,
      token: previewData?.token,
      layout: layout.grid,
      enableScreensharing: true,
      screenshareUid: previewData?.screenShareUid,
      screenshareToken: previewData?.screenShareToken,
      layout: isPinned ? layout.pin : layout.grid,
    });

    setRtmProps({
      token: previewData?.rtmToken,
      uid: previewData?.rtmUid,
      username: previewData?.displayName,
      displayUsername: true,
    });

    console.log("setRtcProps", getRtcProps);
  }, [previewData]);

  const callbacks = {
    EndCall: () => {
      setRtcProps(null);
      setRtmProps(null);
      setInCall(false);
      console.log("End call button clicked");
    },
  };

  switch (dynamic) {
    case "translation":
      dynamicContent = (
        <Formik
          enableReinitialize={true}
          initialValues={translateIntialValues}
          validationSchema={translateSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form>
              <div className="row">
                <div className="col  mb-3">
                  <Input
                    name="apikey"
                    label={_("API_KEY")}
                    placeholder={_("API_KEY")}
                    type="text"
                    className="form-control"
                    errors={formikProps?.errors}
                  />
                </div>
              </div>

              <FieldArray name="text">
                {({ insert, remove, push }) => (
                  <div>
                    {formikProps?.values.text.map((content, index) => (
                      <>
                        <div className="row" key={index}>
                          <div className="col  mb-3">
                            <label
                              htmlFor={`text.${index}.content`}
                              className="form-label"
                            >
                              {_("CONTENT")}
                            </label>
                            <Field
                              name={`text.${index}.content`}
                              placeholder={_("CONTENT")}
                              className="form-control"
                              type="text"
                            />
                            <ErrorMessage
                              name={`text.${index}.content`}
                              component="div"
                              className="error"
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </FieldArray>

              <div className="col d-flex gap-2 justify-content-end mt-2">
                {formikProps.values.text.length > 1 && (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() =>
                      formikProps.setFieldValue(
                        "text",
                        formikProps.values.text.slice(0, -1)
                      )
                    }
                  >
                    Remove
                  </button>
                )}
                <button
                  type="button"
                  className="btn theme-pre"
                  onClick={() =>
                    formikProps.setFieldValue("text", [
                      ...formikProps.values.text,
                      {
                        content: "",
                      },
                    ])
                  }
                >
                  Add More
                </button>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group mb-3">
                    <label htmlFor={`targetLanguages`}>
                      Select Source Language
                    </label>
                    <CustomSelect
                      field={formikProps.getFieldProps(`sourceLanguage`)}
                      form={formikProps}
                      options={questionTags}
                      placeholder="Select Language..."
                      isMulti={false}
                      isClearable={true}
                      classNamePrefix="cstm_select"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor={`targetLanguages`}>
                      Destination Language
                    </label>
                    <CustomSelect
                      field={formikProps.getFieldProps(`targetLanguages`)}
                      form={formikProps}
                      options={questionTags}
                      placeholder="Select Language..."
                      isMulti={true}
                      isClearable={true}
                      classNamePrefix="cstm_select"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap gap-2 action-btn-row">
                <div div className="submitbtn">
                  <button
                    type="submit"
                    className={`btn theme-pre btn-save ${
                      showLoader ? "btn-loader" : ""
                    }`}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
      previewContent = showLoader ? (
        <PreviewSkelton
          count={1}
          translationLength={previewData?.translation?.length}
        />
      ) : (
        <>
          {previewData !== null && previewData?.length > 0 ? (
            <>
              {previewData?.map((item, i) => {
                return (
                  <div className="data-translate-card" key={i}>
                    <div className="preview__header  d-flex align-items-center gap-4">
                      <div className="preview__header__title">
                        <h3 className="mb-sm-0 preview-title width-title">
                          {" "}
                          Language :
                        </h3>
                      </div>
                      <div className="preview__header__language">
                        <span className="preview-data">
                          {" "}
                          {
                            questionTags?.find(
                              (tag) => tag?.value === item?.sourceLanguage
                            )?.label
                          }
                        </span>
                      </div>
                    </div>

                    <div className="preview__body mt-4">
                      <div className="preview__body__question">
                        <div className="preview__body__question__title d-flex align-items-center justify-contetnt-center">
                          <h3 className="mb-sm-0 preview-title width-title">
                            Content :
                          </h3>
                          <div className="preview__body__question__options__option__description">
                            <p className="preview-data">{item?.text}</p>
                          </div>
                        </div>
                        <div className="preview__body__question__options mt-4">
                          <div className="preview__body__question__options__option has-bg">
                            <div className="preview__body__question__options__option__title">
                              <h3 className="mb-sm-0 preview-title translate_title">
                                Translations
                              </h3>
                            </div>
                            {item?.translations?.map((translation, index) => {
                              return (
                                <div
                                  className="preview__body__question__options__option__description d-flex flex-column justify-content-start align-items-start"
                                  key={index}
                                >
                                  <div className="heading d-flex w-100 gap-4 justify-content-start align-items-start">
                                    <h3 className="mb-sm-0 preview-title width-title">
                                      Language :
                                    </h3>
                                    <p className="preview-data">
                                      {" "}
                                      {
                                        questionTags?.find(
                                          (tag) =>
                                            tag?.value === translation?.lang
                                        )?.label
                                      }
                                    </p>
                                  </div>
                                  <div className="heading d-flex w-100 gap-4 justify-content-start align-items-start">
                                    <h3 className="mb-sm-0 preview-title width-title">
                                      Content :
                                    </h3>

                                    <p className="preview-data">
                                      {translation?.translation}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <h3 className="mb-sm-0 preview-title width-title no-preview">
              {" "}
              No Preview Available{" "}
            </h3>
          )}
        </>
      );
      break;
    case "Sentiment Analysis":
      dynamicContent = (
        <Formik
          enableReinitialize={true}
          initialValues={serviceIntialValues}
          validationSchema={serviceSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form>
              <div className="row">
                <div className="col  mb-3">
                  <Input
                    name="apikey"
                    label={_("API_KEY")}
                    placeholder={_("API_KEY")}
                    type="text"
                    className="form-control"
                    errors={formikProps?.errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col  mb-3">
                  <Input
                    name="text"
                    label={_("CONTENT")}
                    placeholder={_("CONTENT")}
                    type="text"
                    className="form-control"
                    errors={formikProps?.errors}
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap gap-2 action-btn-row">
                <div div className="submitbtn">
                  <button
                    type="submit"
                    className={`btn theme-pre btn-save ${
                      showLoader ? "btn-loader" : ""
                    }`}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
      previewContent = showLoader ? (
        <PreviewSkelton
          count={1}
          translationLength={previewData?.translation?.length}
        />
      ) : (
        <>
          {previewData !== null ? (
            <>
              <div className="data-translate-card">
                <div className="preview__header  d-flex align-items-center gap-4">
                  <div className="preview__header__title">
                    <h3 className="mb-sm-0 preview-title width-title">
                      {" "}
                      Text :{" "}
                      <span className="preview-data"> {previewData.text}</span>
                    </h3>
                  </div>
                </div>

                <div className="preview__body mt-4">
                  <div className="preview__body__question">
                    <div className="preview__body__question__title d-flex align-items-center justify-contetnt-center">
                      <h3 className="mb-sm-0 preview-title width-title">
                        Sentiment :
                      </h3>
                      <div className="preview__body__question__options__option__description">
                        <p className="preview-data">
                          {previewData.sentiment.toLowerCase() === "positive"
                            ? "😊 Positive"
                            : previewData.sentiment.toLowerCase() === "negative"
                            ? "😔 Negative"
                            : previewData.sentiment.toLowerCase() === "neutral"
                            ? "😐 Neutral"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h3 className="mb-sm-0 preview-title width-title no-preview">
              {" "}
              No Preview Available{" "}
            </h3>
          )}
        </>
      );
      break;
    case "Response evaluation":
      dynamicContent = (
        <Formik
          enableReinitialize={true}
          initialValues={ResEveInitialValues}
          validationSchema={resEveSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            console.log(formikProps, "formikProps");
            return (
              <Form>
                <div className="row">
                  <div className="col  mb-3">
                    <Input
                      name="apikey"
                      label={_("API_KEY")}
                      placeholder={_("API_KEY")}
                      type="text"
                      className="form-control"
                      errors={formikProps?.errors}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col  mb-3">
                    <Input
                      name="question"
                      label={_("QUESTION")}
                      placeholder={_("QUESTION")}
                      type="text"
                      className="form-control"
                      errors={formikProps?.errors}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col  mb-3">
                    <Input
                      name="answer"
                      label={_("ANSWER")}
                      placeholder={_("ANSWER")}
                      type="text"
                      className="form-control"
                      errors={formikProps?.errors}
                    />
                  </div>
                </div>

                <div className="d-flex flex-wrap gap-2 action-btn-row">
                  <div div className="submitbtn">
                    <button
                      type="submit"
                      className={`btn theme-pre btn-save ${
                        showLoader ? "btn-loader" : ""
                      }`}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      );
      previewContent = showLoader ? (
        <PreviewSkelton
          count={1}
          translationLength={previewData?.translation?.length}
        />
      ) : (
        <>
          {previewData !== null ? (
            <>
              <div className="data-translate-card">
                {/* <div className="preview__header  d-flex align-items-center gap-4">
                  <div className="preview__header__title">
                    <h3 className="mb-sm-0 preview-title width-title">
                      {" "}
                      {previewData.match.split("\n")[0]}
                    </h3>
                  </div>
                </div> */}

                <div className="preview__body mt-4">
                  <div className="preview__body__question">
                    <div className="preview__body__question__title d-flex align-items-center justify-contetnt-center">
                      <h3 className="mb-sm-0 preview-title width-title">
                        Analysis Result:
                      </h3>
                      <div className="preview__body__question__options__option__description mx-2 ">
                        <p className="preview-data">
                          {/* true */}
                          {previewData?.match?.relevant ? "True" : "False"}
                        </p>
                      </div>
                    </div>
                    <div className="preview__body__question__options mt-4">
                      <div className="preview__body__question__options__option has-bg">
                        <div className="preview__body__question__options__option__description d-flex flex-column justify-content-start align-items-start">
                          <div className="heading d-flex w-100 gap-4 justify-content-start align-items-start">
                            <h3 className="mb-sm-0 preview-title width-title">
                              Reason :
                            </h3>
                            <p className="preview-data">
                              {" "}
                              {previewData?.match?.reason}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h3 className="mb-sm-0 preview-title width-title no-preview">
              {" "}
              No Preview Available{" "}
            </h3>
          )}
        </>
      );
      break;
    case "conference":
      dynamicContent = (
        <Formik
          enableReinitialize={true}
          initialValues={conferenceInitial}
          //  validationSchema={resEveSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => {
            // Destructure values from formikProps
            return (
              <Form>
                <div className="row">
                  <div className="col mb-3">
                    <Input
                      name="apikey"
                      label={_("API_KEY")}
                      placeholder={_("API_KEY")}
                      type="text"
                      className="form-control"
                      errors={formikProps?.errors}
                      value={formikProps?.values.apikey} // Use values.apikey here
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <Input
                      name="channelName"
                      label="channel Name"
                      placeholder="channel Name"
                      type="text"
                      className="form-control channel-Name"
                      errors={formikProps?.errors}
                      value={formikProps?.values.channelName} // Use values.channelName here
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <Input
                      name="userName"
                      label="User Name"
                      placeholder="User Name"
                      type="text"
                      className="form-control User-Name"
                      errors={formikProps?.errors}
                      value={formikProps?.values.userName} // Use values.channelName here
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <Input
                      name="AppID"
                      label="MemberId"
                      placeholder="MemberId"
                      type="text"
                      className="form-control MemberId"
                      errors={formikProps?.errors}
                      value={formikProps?.values.AppID} // Use values.AppID here
                    />
                  </div>
                </div>

                <div className="d-flex flex-wrap gap-2 action-btn-row">
                  <div className="submitbtn">
                    <button
                      type="submit"
                      className={`btn theme-pre btn-save ${
                        showLoader ? "btn-loader" : ""
                      }`}
                    >
                      Join Call
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      );
      previewContent = showLoader ? (
        <PreviewSkelton
          count={1}
          translationLength={previewData?.translation?.length}
        />
      ) : (
        <>
          {previewData !== null ? (
            <>
              <div className="data-translate-card">
                {inCall ? (
                  <>
                    <button
                      style={styles.btn}
                      onClick={() => setPinned(!isPinned)}
                    >
                      Change Layout
                    </button>

                    <div style={styles.container} className="agora-container">
                      {getRtcProps != null && (
                        <AgoraUIKit
                          rtcProps={getRtcProps}
                          rtmProps={RtmProps}
                          callbacks={callbacks}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <h3 className="mb-sm-0 preview-title width-title no-preview">
                    {" "}
                    No Preview Available{" "}
                  </h3>
                )}
              </div>
            </>
          ) : (
            <h3 className="mb-sm-0 preview-title width-title no-preview">
              {" "}
              No Preview Available{" "}
            </h3>
          )}
        </>
      );
      break;
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h3 className="mb-sm-0 page-title">{_("CREATE_PLAYGROUND")}</h3>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard/keys">{_("PLAYGROUND_LISTING")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("ADD")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="card custom-card previewing">
              <div className="card-body">
                <h4 className="card-title">{_("PLAYGROUND")}</h4>
                <p className="card-title-desc">{_("PLAYGROUND_INFO")}</p>
                {dynamicContent}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card-preview p-4">
              <div className="preview_card">
                <div className="preview-body">
                  <h3 className="mb-sm-0 page-title">Preview</h3>
                  <p className="card-title-desc">Preview your page here</p>
                  {previewContent}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPlayground;
