import { DELETE_KEY, KEY_LIST, KEY_STATUS,FETCH_KEY_LIST } from "../actions/keys";

const initialState = {
  keyListing: null,
  fetchKeyList:null
};

const keys = (state = initialState, action) => {


  switch (action.type) {
   
    case KEY_LIST:
      return {
        ...state,
        keyListing: action.payload
      };
    break;
    case DELETE_KEY:
      const zone = { ...state.keyListing };
      let cndex = zone?.data?.findIndex((item) => item.id === action.id);
      zone?.data?.splice(cndex, 1);
      return {
        ...state,
        keyListing: zone,
      };
    break;
    case KEY_STATUS:
      let serve = { ...state.keyListing };
      let sndex = serve?.data?.findIndex((item) => item.id === action?.payload?.id);
      serve.data[sndex] = {...serve.data[sndex], ...action.payload}
      return {
        ...state,
        keyListing: {...serve},
      };
      case FETCH_KEY_LIST:
      return {
        fetchKeyList : Date.now()
    }
    break;

    default:
  }
  return state;
}
export default keys;