import { Fragment } from "react";
import { Controller, useController } from "react-hook-form";

function SingleCheckBox({
  control,
  defaultValue,
  rules,
  label,
  name,
  value,
  readOnly,
  int,
  onSelect,
  trueValue,
  falseValue,
  disabled,
  array,
  labelClass,
}) {
  const handleChange = (val, onChange) => {
    if (trueValue !== undefined && falseValue !== undefined) {
      if (int !== undefined)
        onChange(val ? parseInt(trueValue) : parseInt(falseValue));
      else onChange(val ? trueValue : falseValue);
    } else {
      onChange(val);
    }

    if (onSelect) onSelect(val);
  };

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  return (
    <>
      <div
        className={`f-in w-100 form-check ${
          error !== undefined ? "f-error" : ""
        }`}
      >
        <input
          {...field}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          name={name}
          type="checkbox"
          value={field.value}
          className="form-check-input"
          checked={field.value}
          onChange={(e) => handleChange(e.target.checked, field.onChange)}
        />
        <label className={labelClass}>{label}</label>
        {console.log(error?.message, "CHEK")}
        {error?.message ? (
          <p className="error mb-1 flex-row w-100  d-flex align-items-center">
            <span className="icon info-icon"></span>
            {error.message}
          </p>
        ) : null}
      </div>
    </>
  );
}

export default SingleCheckBox;
