import { SERVICE_LIST, DELETE_SERVICE, SERVICE_STATUS} from "../actions/services";

const initialState = {
  serviceListing: null
};

const services = (state = initialState, action) => {


  switch (action.type) {
   
    case SERVICE_LIST:
      return {
        ...state,
        serviceListing: action.payload
      };
    break;
    case DELETE_SERVICE:
      const zone = { ...state.serviceListing };
      let cndex = zone?.data?.findIndex((item) => item.id === action.id);
      zone?.data?.splice(cndex, 1);
      return {
        ...state,
        serviceListing: zone,
      };
    break;
    case SERVICE_STATUS:
      let serve = { ...state.serviceListing };
      let sndex = serve?.data?.findIndex((item) => item.id === action?.payload?.id);
      serve.data[sndex] = {...serve.data[sndex], ...action.payload}
      return {
        ...state,
        serviceListing: {...serve},
      };
    break;

    default:
  }
  return state;
}
export default services;