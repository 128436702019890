import { Link, useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { useEffect, useState } from "react";
import { _ } from "../../locale";
import { useDispatch } from "react-redux";
import moment from "moment";
import { FETCH_KEY_LIST, KEY_LIST, generateKey } from "../../actions/keys";
import toast from "react-hot-toast";
import CodeModal from "../../components/CodeModal";

function ManageKeySlide({ slideData, index, srno }) {
  //   local variables
  const [status, setStatus] = useState(slideData?.createdBy?.status);
  const [showLoader, setShowLoader] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setStatus(slideData?.createdBy?.status);
  }, [slideData?.createdBy?.status]);

  const keyGenHandler = async (id) => {
    try {
      const genKey = await generateKey({ serviceId: id });

      // dispatch({ type: KEY_LIST, payload: genKey?.data?.responseData });
      dispatch({ type: FETCH_KEY_LIST, payload: Date.now() });
      setShowLoader(false);
    } catch ({ response, request }) {
      // console.log(error, '====');
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
      setShowLoader(false);
    }
  };

  const playGroundHandler = async (id) => {
    navigate(`/dashboard/playgrounds/${id}/${slideData.name}`);
  };

  const openCodeModal = async (id) => {
    setShowCodeModal(true);
    setSelectedKey(id);
  };

  const onHide = () => {
    setShowCodeModal(false);
  };

  return (
    <>
      <tr>
        <td>{parseInt(srno + parseInt(index + 1))}</td>
        <td>
          <p className="font-size-14 mb-1">{slideData?.name ?? null}</p>
        </td>
        <td>
          <p className="font-size-14 mb-1">
            {slideData?.key ?? "Not generated"}
          </p>
        </td>
        {/* <td>
      <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
      <p class="text-muted mb-0">{_('BY')} {slideData?.createdBy?.name ?? null}</p>
    </td>
   
    <td>
      <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{slideData?.updatedAt ? moment(slideData?.updatedAt).format('lll') : moment(slideData?.createdAt).format('ll')}</a></h5>
      <p class="text-muted mb-0">{_('BY')} {slideData?.updatedBy?.name ? slideData?.updatedBy?.name : slideData?.author?.name }</p>
    </td> */}
        <td>
          <a href="javascript:void(0)">
            {status ? (
              <span className="badge bg-success">{_("ACTIVE")}</span>
            ) : (
              <span className="badge bg-danger">{_("NOT_ACTIVE")}</span>
            )}
          </a>
        </td>
        <td>
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <td className="d-flex gap-2">
                <button
                  className="btn btn-primary"
                  onClick={() => keyGenHandler(slideData?.id)}
                >
                  {slideData?.key === null ? (
                    <>{_("GENERATE_KEY")}</>
                  ) : (
                    <>{_("REGENERATE_KEY")}</>
                  )}
                </button>
                {slideData?.key && (
                  <>
                    <button
                      className="btn btn-primary"
                      onClick={() => playGroundHandler(slideData?.key)}
                    >
                      {_("PLAYGROUND")}
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => openCodeModal(slideData?.key)}
                    >
                      {_("CODE")}
                    </button>
                  </>
                )}
              </td>
            </li>
            {/* <li>
        <Link to={`/dashboard/service/${slideData.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
    </li>
    <li>
      <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
    </li> */}
          </ul>
        </td>
      </tr>
      <CodeModal show={showCodeModal} onHide={onHide} apiKey={selectedKey} />
    </>
  );
}

export default ManageKeySlide;
