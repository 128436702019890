export const locale = {
    //start english
    en: {
        'PRODUCT_NAME': 'QFi-AI',
        'SIGN_IN_CONTINUE': 'Sign in to continue to Qfi-AI',
        'CRAFTED_WITH': 'Qfi-AI. Crafted with',
        'BY_ILLUMINZ': 'by Illuminz',
        'NO_INTERNET': 'You do not have internet connection',
        'EMAIL': 'Email',
        'EMAIL_REQUIRED': 'Email is required',
        'EMAIL_VALID': 'Please enter valid email address',
        'PASSWORD': 'Password',
        'PASSWORD_REQUIRED': 'Password is required',
        'PASSWORD_VALIDATE': 'Password must be more than four characters',
        'CREATE_PASSWORD': 'Create Password',
        'CONFIRM_PASS': 'Confirm Password',
        'LOGIN': 'Log In',
        'CATEGORY_TYPE': 'Category type',
        'NAME': 'Name',
        'ACTION': 'Action',
        'ADD_CATEGORY_TYPE': 'Add New Category Type',
        'ADD': 'Add',
        'CATEGORY_TYPE_INFO': 'Set basic info for category type',
        'TITLE': 'Title',
        'TITLE_REQUIRED': 'Title is required',
        'DESCRIPTION': 'Description',
        'DESCRIPTION_REQUIRED': 'Description is required',
        'SAVE': 'Save',
        'CANCEL': 'Cancel',
        'STATUS': 'Status',
        'ACTIVE': 'Active',
        'NOT_ACTIVE': 'Not Active',
        'EDIT_CATEGORY_TYPE': 'Edit Category Type',
        'EDIT': 'Edit',
        'CATEGORY': 'Category',
        'ADD_CATEGORY': 'Add Category',
        'IMAGE':'Image',
        'PARENT_CATEGORY':'Parent Category',
        'CONFIRM_DELETE':'Are you sure to delete this record?',
        'CONFIRM_PUBLISH':'Are you sure to you want to publish this question to the newer version?',
        'YES':'Yes',
        'NO': 'No',
        'CONFIRM_DEACTIVATE': 'Are you sure to deactivate this record?',
        'CONFIRM_ACTIVATE': 'Are you sure to activate this record?',
        'CATEGORY_INFO': 'Set basic info for category',
        'Menu': 'Menu',
        'MANAGEMENT':'Management',
        'DASHBOARD':'Dashboard',
        'EMAIL_TEMPLATE':'Email Template',
        'ADD_EMAIL':'Add Email',
        'ACCOUNT_INFO': 'Set basic info for account',
        'SUBJECT':'Subject',
        'SUBJECT_REQUIRED':'Subject is required',
        'CODE':'Code',
        'CODE_REQUIRED':'Code is required',
        'REPLACEMENTS':'Replacements',
        'REPLACEMENTS_REQUIRED':'Replacements is required',
        'PLAN': 'Plan',
        'PLAN_LISTING': 'Plan listing',
        'CREATE_PLAN': 'Create Plan',
        'PLAN_INFO': 'Set basic info for plan',
        'NAME_REQUIRED': 'Name is required',
        'PAYMENT_GATEWAY': 'Payment gateway',
        'PAYMENT_GATEWAY_REQUIRED': 'Payment gateway name is required',
        'PLAN_ID': 'Plan id',
        'PLAN_ID_REQUIRED': 'Plan id is required',
        'DATE': 'Date',
        'POST': 'Post',
        'POST_LISTING': 'Post listing',
        'CREATE_POST': 'Create post',
        'POST_INFO': 'Set basic info for post',
        'EXCERPT': 'Excerpt',
        'EXCERPT_REQUIRED': 'Excerpt is required',
        'POST_TYPE': 'Post type',
        'POST_TYPE_REQUIRED': 'Post type is required',
        'SERVICE_PROVIDER_REQUIRED': 'Service Provider is required',
        'CREATED_BY': 'Created by',
        'UPDATED_BY': 'Updated by',
        'BY': 'By:',
        'PAGE': 'Page',
        'EDIT_POST': 'Edit post',
        'PAGE_LISTING': 'Page listing',
        'CREATE_PAGE': 'Create page',
        'PAGE_INFO': 'Set basic info for page',
        'EDIT_PAGE': 'Edit page',
        'VIEW': 'View',
        'SUB_CATEGORY':'Sub category',
        'ACCESS_CONTROL': 'Access control',
        'PERMISSIONS':'Permissions',
        'ADD_PERMISSION': 'Add permission',
        'PERMISSION': 'Permission',
        'POST_CATEGORY': 'Post category',
        'ADD_POST_CATEGORY': 'Add post category',
        'VIEW_SUBCATEGORY': 'View subcategory',
        'EDIT_CATEGORY': 'Edit category',
        'CREATE_PERMISSION': 'Create permission',
        'ADMINONLY':'Admin only',
        'PERMISSION_CODE': 'Permission code',
        'PERMISSION_CODE_REQUIRED': 'Permission code required',
        'PERMISSION_INFO': 'Set basic info for permission',
        'ROLES': 'Roles',
        'ROLE': 'Role',
        'ADD_ROLE': 'Add role',
        'ROLE_INFO': 'Set basic info for role',
        'PAGE_CATEGORY': 'Page category',
        'ADD_PAGE_CATEGORY': 'Add page category',
        'SUBSCRIPTION_PLAN': 'Subscription plan',
        'STORAGE_REQUIRED': 'Storage is required',
        'STORAGE': 'Storage',
        'MB': 'mb',
        'DOCUMENT': 'Document',
        'DOCUMENTS': 'Documents',
        'DOCUMENT_TYPE': 'Document type',
        'ADD_DOCUMENT_TYPE': 'Add document type',
        'SYSTEM_DEFAULT': 'System default',
        'DOCUMENT_INFO': 'Set basic info for directory',
        'EDIT_DOCUMENT': 'Edit document',
        'SERVICE_ZONE': 'Service zone',
        'ADD_ZONE': 'Add Zone',
        'SERVICE_ZONE_INFO': 'Set basic info for service zone',
        'SERVICES': 'Services',
        'SERVICES_LISTING': 'Services Listing',
        'SERVICE': 'Service',
        'ADD_SERVICE': 'Add service',
        'EDIT_SERVICE': 'Edit service',
        'SERVICE_INFO': 'Set basic info for services',
        'VIEW_SERVICES': 'View services',
        'INCLUDED': 'Included',
        'INCLUDED_REQUIRED': 'Included text is required',
        'NOT_INCLUDED': 'Excluded',
        'EXCLUDED_REQUIRED': 'Not included text is required',
        'ONLINE_SERVICE': 'Online service',
        'QUOTATION_SERVICE': 'Quotation service',
        'ONSITE_SERVICE': 'Onsite service',
        'ONSITE_FIXED_SERVICE': 'Onsite fixed service',
        'ONSITE_PER_HOUR_SERVICE': 'Onsite per hour service',
        'ONSITE_PER_SQUAR_FEET_SERVICE': 'Onsite per square feet service',
        'DOCUMENT_TYPE_INFO': 'Set basic info for document type',
        'TYPE': 'Type',
        'TYPE_REQUIRED': 'Document type is required',
        'IS_REQUIRED': 'Is required',
        'EDIT_DOCUMENT_TYPE': 'Edit document type',
        'REQUIRED': 'Required',
        'REQUIREMENT': 'Is Required',
        'OPTIONAL': 'Optional',
        'VIEW_COMMISSION': 'View commission',
        'SERVICE_ZONE_COMMISSION': 'Zone commission',
        'ADD_COMMISSION': 'Add commission',
        'NORMAL_COMMISSION_RATE': 'Normal commission',
        'PREMIUM_COMMISSION_RATE': 'Premium commission',
        'SETTINGS': 'Settings',
        'SETTING_INFO': 'Set basic info for settings',
        'NORMAL_USER_COMMISSION': 'Normal user commission rate',
        'PREMIUM_USER_COMMISSION': 'Premium user commission rate',
        'ALL_RIGHTS_RESERVED': 'All Rights Reserved',
        'SETTINGS_UPDATED_SUCCESSFULLY':'Settings has been updated successfully',
        'SOMETHING_WENT_WRONG': 'Something went wrong',
        'COMMISSION_FOR': 'Commission rate for',
        'ADD_ZONE_COMMISSION': 'Add zone commission',
        'ZONE_COMMISSION': 'Zone commission',
        'ZONE_COMMISSION_INFO': 'Set basic info for zone commission',
        'CATEGORY_REQUIRED': 'Choose category first',
        'SERVICES_REQUIRED': 'Choose service first',
        'NORMAL_USER_COMMISSION_REQUIRED': 'Normal user commission is required',
        'PREMIUM_USER_COMMISSION_REQUIRED': 'Premium user commission is required',
        'STATIC_PAGES': 'Static pages',
        'NO_RECORD_EXIST': 'No Record exist.',
        'CLICK_HERE': 'Click here',
        'CREATE_NEW_RECORD': 'to create new record',
        'CREATE_NEW': 'to create new',
        'EDIT_ROLE': 'Edit role',
        'CREATE_VALID_GEOFENCE': 'Geofence is not valid',
        'DRAW_GEOFENCE': 'Draw geofence',
        'STAFF': 'Staff',
        'ADD_STAFF': 'Add staff',
        'EDIT_STAFF': 'Edit staff',
        'SERVICE_PROVIDER': 'Service provider',
        'USERS': 'Users',
        'CONTACT_NUMBER': 'Phone',
        'ROLE_REQUIRED': 'Role is required',
        'STAFF_INFO':'Set basic info for staff',
        'CHOOSE_ATLEAST_ONE_SERVICE': 'Choose atleast one service',
        'PROFILE_INFO': 'Profile Info',
        'STRIPE_CONNECTED': 'Stripe connected',
        'EMAIL_VERIFIED': 'Email verified',
        'CONNECTED': 'Connected',
        'NOT_CONNECTED': 'Not connected',
        'NOT_VERIFIED': 'Not verified',
        'VERIFIED': 'Verified',
        'CATEGORY_AND_SERVICES': 'Category & services',
        'QUOTATION': 'Quotation',
        'FIXED': 'Fixed',
        'PER_SQR_FT': 'Per sqr ft',
        'PER_HOUR': 'Per hour',
        'ONSITE': 'Onsite',
        'ONLINE': 'Online',
        'YES': 'Yes',
        'NO': 'No',
        'SERVICE_ORIGIN': 'Service origin',
        'ATTRIBUTE_TYPE': 'Attribute type',
        'STANDARD_PRICE': 'Standard price',
        'ESTIMATED_TIME': 'Estimated time',
        'PRIORITY_PRICE': 'Priority price',
        'APPROVED_STATUS': 'Approval status',
        'REJECTED': 'Rejected',
        'APPROVED': 'Approved',
        'PENDING': 'Pending',
        'ACTION_REQUIRED': 'Choose an action first',
        'REASON_REQUIRED': 'Please mention the reason',
        'REASON': 'Reason',
        'APPROVED_SUCCESSFULLY': 'Document has been approved successfully',
        'REJECTED_SUCCESSFULLY': 'Document has been rejected successfully',
        'EDIT_ZONE_COMMISSION': 'Edit zone commission',
        'EDIT_ZONE': 'Edit zone',
        'YES': 'Yes',
        'YES': 'Yes',
        'YES': 'Yes',
        'YES': 'Yes',
        'HOMESCORE':'Home Score',
        'SCORE':"Score",
        'SAVECHANGES': 'Save Changes',
        'QUESTION_TYPE':'Select Question Type...',
        'DEVICE_MANAGEMENT':'Device Management',
        'APPLIANCES_MANAGEMENT':'Manage Appliances',
        'DEVICE_LISTING':'Device Listing',
        'APPLIANCE_LISTING':'Appliance Listing',
        'ADD_DEVICE_CATEGORY':'Add Device Category',
        'DEVICE_CATEGORY':'Device Category',
        'DEVICE_CATEGORY_REQUIRED': 'Device is required',
        'EDIT_DEVICE_CATEGORY':'Edit Device Category',
        'ADD_QUESTION':"Add Question",
        'LINK_QUESTION':"Link Question",
        'QUESTIONS':"Questions",
        'QUESTIONS_LISTING':"Questions Listing",
        'QUESTION':"Question",
        'VIEW_QUESTIONS':"View Questions",
        'CREATE_QUESTION':"Create Question",
        'Edit_QUESTION':"Edit Question",
        'EDIT_QUESTION_CATEGORY':'Edit Question Category',
        "ADD_DEVICE":'Add Device',
        "ADD_APPLIANCE":'Add Appliance',
        "MANAGE_DEVICE":"Manage Device",
        "CREATE_APPLIANCE":"Create Appliance",
        "APPLIANCE_INFO":"Set basic info for appliance",
        "APPLIANCE":"Appliance",
        "MANAGE_VERSIONS":"Manage Versions",
        "Select_VERSION":"Select Version",
        "APPLY_NOW":"Apply Now",
        "RESET":"Reset",
        "QUESTION_NAME":"Question Name",
        "Select_MODE":"Select Mode",
        "VERSION":"Version",
        "DESCRIPTION_MAXLENGTH":"Description must be less than 500 characters",
        "MIN":"Min",
        "MAX":"Max",
        "TAGS":"Tags",
        "NUMBER":"Number",
        "TOTAL_SCORE":"Total Score",
        "QUESTION_CODE":"Question Code",
        "LINKED":"Linked",
        "ACCOUNTS":"Accounts",
        "ADD_ACCOUNT":"Add Account",
       "NEW_ACCOUNT":"New Account",
       "EDIT_ACCOUNT":"Edit Account",
       "PLAYGROUNDS":"Playgrounds",
       "PLAYGROUND":"Playground",
       "PLAYGROUND_INFO":"Set basic info for playground",
       "ADD_PLAYGROUND":"Add Playground",
         "NEW_PLAYGROUND":"New Playground",
            "EDIT_PLAYGROUND":"Edit Playground",
            "PLAYGROUND_LISTING": "Playground Listing",
            "CREATE_PLAYGROUND":"Create Playground",
            "SERVICE_PROVIDER":"Service Provider",
            "IS_DEFAULT":"Default",
            "API_KEY":"API Key",
            "API_KEY_REQUIRED":"API Key is required",
            "KEY1":"Enter Key 1",
            "KEY_REQUIRED":"Key is required",
            "KEY2":"Enter Key 2",
            "TEXT_TRANSLATION":"Enter Translation Link",
            "TRANSLATION_LINK_REQUIRED":"Translation Link is Required",
            "DOCUMENT_TRANSLATION":"Enter Document Translation Link",
            "DOCUMENT_TRANSLATION_REQUIRED":"Enter Document Translation Link",
            "MANAGE_KEYS":"Manage Keys",
            "KEYS":"Keys",
            "KEY_LISTING":"Key Listing",
            "ADD_KEY":"Add Key",
            "GENERATE_KEY":"Generate Key",
            "REGENERATE_KEY":"Regenerate Key",
            "KEY_NAME":"Key Name",
            "CONTENT":"Content",
            "GOOGLE_KEY":"Enter Google Gemini Key",
            "GOOGLE_REQUIRED":"Google Gemini is required",



    },
    //end english

};
