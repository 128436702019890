import { Route, Routes } from "react-router-dom";
import Header from "../../components/common/Header";
import LeftNavigation from "../../components/common/LeftNavigation";
import Dashboard from "../dashboard/Dashboard";
import AddService from "../services/AddService";
import EditService from "../services/EditService";
import ManageAccounts from "../account/ManageAccounts";
import AddAccount from "../account/AddAccount";
import EditAccount from "../account/EditAccount";
import ManageServices from "../page/ManageService";
import AddPlayground from "../page/AddPlayground";
import ManageKeys from "../page/ManageKeys";
import AddServiceFormik from "../services/AddServiceFormik";
import CodeSnippetGenerator from "../../components/CodeSnippetGenerator";
// import AddServiceFormik from "../services/AddServiceFormik";
function Layout() {
  return (
    <div id="layout-wrapper">
      <Header />
      <LeftNavigation />

      <div className=" overflow-auto">
        <Routes>
          <Route index element={<Dashboard />} />

          {/* Services  */}
          <Route path="/services" element={<ManageServices />} />
          <Route path="/service/add" element={<AddServiceFormik />} />
          <Route path="/service/formik" element={<AddService />} />
          {/* <Route path="/service/formik/:id/edit" element={<AddServiceFormik />} /> */}
          <Route path="/service/:id/edit" element={<AddServiceFormik />} />
          <Route path="/code" element={<CodeSnippetGenerator />} />
          {/* PlayGround */}
          <Route path="/playgrounds/:id/:dynamic" element={<AddPlayground />} />
          <Route path="/playground/:id/edit" element={<AddPlayground />} />

          {/* Keys  */}
          <Route path="/keys" element={<ManageKeys />} />
          <Route path="/key/add" element={<AddService />} />
          <Route path="/key/:id/edit" element={<AddService />} />

          {/* Accounts */}
          <Route path="/accounts" element={<ManageAccounts />} />
          <Route path="/account/add" element={<AddAccount />} />
          <Route path="/account/:id/edit" element={<EditAccount />} />
        </Routes>
      </div>
    </div>
  );
}

export default Layout;
