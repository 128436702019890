import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { addEmailTemplate } from "../../actions/email";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import JoditEdior from "../../components/FormFields/JoditEditor";
// import { addBlogs, listGroupOrCategory } from "../../../actions/cms";
// import { useEffect, useState } from "react";

function AddAccount() {
  // use hooks
  const {control, handleSubmit, setValue, formState: { isSubmitting }, } = useForm();
  const navigate = useNavigate();

  // on form submissions
  const onSubmit = async (formData) => {
    console.log(formData, "formData");
    try {
      await addEmailTemplate({ ...formData});
      navigate('/dashboard/email-template');
    } catch ({ response, request }) {
      if (response) {
        // toast.error(response?.data?.message);
      } else if (request) {
        // toast.error("You do not have internet connection");
      }
    }
  };

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('ADD_ACCOUNT')}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="/dashboard/accounts">{_('ACCOUNTS')}</Link></li>
                    <li className="breadcrumb-item active">{_('ADD')}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_('NEW_ACCOUNT')}</h4>
                <p className="card-title-desc">{_('ACCOUNT_INFO')}</p>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                  
                  <div className="row">
                    <div className="mb-3">
                      <label className="form-label">{_('TITLE')}</label>
                      <Inputfield
                        control={control}
                        name={"title"}
                        placeholder={_('TITLE')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('TITLE_REQUIRED') },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3">
                      <label className="form-label">{_('SUBJECT')}</label>
                      <Inputfield
                        control={control}
                        name={"subject"}
                        placeholder={_('SUBJECT')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('SUBJECT_REQUIRED') },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{_('CODE')}</label>
                        <Inputfield
                          control={control}
                          name={"code"}
                          placeholder={_('CODE')}
                          normalize={(e) => e.replace(/^\s+/g, "")}
                          type="text"
                          inputClassName={"form-control"}
                          rules={{
                            required: { value: true, message: _('CODE_REQUIRED') },
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">{_('REPLACEMENTS')}</label>
                        <Inputfield
                          control={control}
                          name={"replacements"}
                          placeholder={_('REPLACEMENTS')}
                          normalize={(e) => e.replace(/^\s+/g, "")}
                          type="text"
                          inputClassName={"form-control"}
                          rules={{
                            required: { value: true, message: _('REPLACEMENTS_REQUIRED') },
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3">
                      <JoditEdior
                        control={control}
                        name={"message"}
                        label={_('DESCRIPTION')}
                        errorClass="error-msg"
                        rules={{
                          required: {
                            value: true,
                            message: _('DESCRIPTION_REQUIRED'),
                          },
                        }}
                      />
                    </div>
                  </div>


                  <div className="d-flex flex-wrap gap-2">
                    <button type="submit" disabled={isSubmitting} className={`btn  btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>

                      {_('SAVE')}
                    </button>
                    <Link to="/dashboard/accounts" className="btn btn-secondary waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAccount;
