 const Controls = ( props) => {
    const { localMicrophoneTrack, localCameraTrack } = props;
    const toggleMute = async () => {
 if (localMicrophoneTrack) {
    try {
      // Await the promise returned by setEnabled
      await localMicrophoneTrack.setEnabled(!localMicrophoneTrack.enabled);
      console.log(localMicrophoneTrack, "localMicrophoneTrack toggle enabled");
    } catch (error) {
      console.error("Error toggling microphone track:", error);
    }
 }
};


    return (
      <div style={styles.btnContainer}>
        <button style={{padding:'10px 20px'}} onClick={toggleMute}>Mute Mic</button>
        <button style={{padding:'10px 20px'}} onClick={() =>  localCameraTrack.setMuted(!localCameraTrack.muted)}>Mute Cam</button>
      </div>
    );
  };


  export default Controls;




  const styles = {
    grid: {
      width: "100%",
      height: "100%",
      display: "grid",
    },
    gridCell: { height: "100%", width: "100%" },
    container: {
      display: "flex",
      flexDirection: "column" ,
      flex: 1,
      justifyContent: "center",
    },
    btnContainer: {
      display: "flex",
      flexDirection: "row" ,
      alignSelf: "center",
      width: "50%",
      justifyContent: "space-evenly",
    },
  };