import axios from "axios";
import {BASE_URL} from "../constants";

export const SERVICE_LIST = 'SERVICE_LIST';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const SERVICE_STATUS = 'SERVICE_STATUS';

//category crud
export const getServicesListing = (props) => {
    return axios({
        method: 'GET',
        params: props,
        url: `/service/list`
    });
};
export function getServiceById(id){
    return axios({
        method: 'GET',
        // params: payload,
        url: '/service/'+id
    })
}
export const addService = (props) => {
    return axios({
        method: 'POST',
        data: props,
        url: '/service'
    });
};
export function editService(payload, id){
    return axios({
        method: 'PATCH',
        data: payload,
        url: `/service/${id}`
    })
}
export function deleteService(id){

    return axios({
        method: 'DELETE',
        url: `/service/${id}`
    })
}
export function updateServiceStatus(payload){
    return axios({
        method: 'PATCH',
        data: payload,
        url: '/services'
    })
}