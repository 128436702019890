import { useController } from "react-hook-form";

function SingleSelectCheckbox({
  name,
  control,
  rules,
  defaultValue,
  options,
  optionValue,
  onChange,
}) {
  // use hooks
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  //   on Change input
  const onInputChange = (checked, _value) => {
    let newValue;
    if (checked) {
      if (Array.isArray(field.value)) {
        newValue = [...field?.value, _value];
      } else {
        newValue = [_value];
      }
    } else {
      let array = [...field.value];
      let index = array.findIndex((item) => item == _value);
      array.splice(index, 1);
      if (array?.length > 0) newValue = array;
      else newValue = "";
    }

    if (onChange) onChange(newValue);
    field.onChange(newValue);
  };

  return (
    <>
      <div className="form-row">
        <div className={`form-in w-100 ${error ? "required-field" : ""}`}>
          {options?.map((_option, index) => {
            return (
              <div className="f-in w-100 form-check" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={name}
                  checked={
                    field?.value?.length &&
                    field?.value?.includes(_option?.[optionValue])
                  }
                  onChange={(e) =>
                    onInputChange(e.target.checked, _option[optionValue])
                  }
                />
                <label className="form-check-label justify-start" for={name}>
                  {_option?.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

/**
 * @property defaults
 */
SingleSelectCheckbox.defaultProps = {
  defaultValue: "",
  rules: {},
  errorClass: "error",
  onChange: (value) => value,
  optionLabel: "name",
  optionValue: "value",
};

export default SingleSelectCheckbox;
