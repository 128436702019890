import React, { useState,useEffect } from "react";
import CustomSelect from "./FormFields/CustomSelect";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import axios from "axios";
import LanguageCode from "./LanguageCode";

const CodeSnippetGenerator = () => {

  let initialValues = {
    language: "",
  };

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [codeSnippet, setCodeSnippet] = useState("");

  const languages = [
    { value: "Javascript", label: "Javascript"},
    { value: "C# - HttpClient", label: "C# - HttpClient"},
    { value: "cURL", label: "cURL"},
    { value: "Dart - dio", label: "Dart - dio"},
    { value: "Dart - http", label: "Dart - http"},
    {value:"Go - Native", label:"Go - Native"},
    {value:"php", label:"PHP"}
  ]


  
const languageHandler = async (props,value) => {
  console.log(props,value, "props,value.value")
  setSelectedLanguage(value);
}
 console.log(selectedLanguage, "selectedLanguage")
  
  

  

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
            <div className="col-lg-12 ">
            <div className="card custom-card p-4">

        {/* create formik form */}
                <Formik 
                initialValues={initialValues}
                enableReinitialize={true}
                >
                  {(formikProps) => (
                    <Form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label htmlFor="language">Language</label>
                            <CustomSelect
                              field={formikProps.getFieldProps(`language`)}
                              form={formikProps}
                              options={languages}
                              placeholder="Select type..."
                              isMulti={false}
                              isClearable={true}
                              onChangeCallback={languageHandler}
                              classNamePrefix={"cstm_select"}
                            />
                          </div>
                        </div>
                      </div>
                      
                    </Form>
                  )}
                </Formik>
            <LanguageCode language={selectedLanguage}/>

            </div>
            </div>
            
    </div>
    </div>
    </div>
  );
};

export default CodeSnippetGenerator;
