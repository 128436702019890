import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  addService,
  editService,
  getServiceById,
} from "../../actions/services";
import { _ } from "../../locale";
import { toast } from "react-hot-toast";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import Input from "../../components/common/input";
import { removeEmptyStringKeys, serviceValidationSchema } from "../page/schema";
import CustomSelect from "../../components/FormFields/CustomSelect";
import { set } from "react-hook-form";
import SingleCheckBox from "../../components/common/SingleCheckBox";
import CheckBox from "../../components/common/CheckBox";
import { determineServiceProviders } from "../../utilities";

function AddServiceFormik() {
  // use hooks
  const { id } = useParams();
  const [services, setServices] = useState(null);


  const navigate = useNavigate();
const SERVICE_PROVIDERS = [
  {
    label: "Google Gemini",
    value: "google-gemini",
  },
  {
    label: "Azure",
    value: "azure",
  },
]
  


  let initialValues = {
    name: "",
    providers: [
      {
        name: "",
        config: {
          key1: "",
          key2: "",
          textTranslation: "",
          documentTranslation: "",
          googleKey:""
        },
        isDefault: "",
      },
    ],
  };

  if (id) {
    initialValues = {
      name: services?.name ?? "",
      providers:
        services?.Providers?.map((provider) => {
          return {
            name: provider?.name,
            config: {
              key1: provider?.config?.key1,
              key2: provider?.config?.key2,
              textTranslation: provider?.config?.textTranslation,
              documentTranslation: provider?.config?.documentTranslation,
              googleKey:provider?.config?.googleKey
            },
            isDefault: provider?.isDefault,
          };
        }) ?? [],
    };
  }
  const [showProperty, setShowProperty] = useState(
    initialValues?.providers?.map((provider) => provider?.name !== "")
  );

  // on form submissions
  const onSubmit = async (values) => {
   
    removeEmptyStringKeys(values);

    

    if (id) {
      try {
        const createReq = await editService(values, id);
        let { data, status } = createReq;

        toast.success(data?.message);
        navigate("/dashboard/services");
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
      }
    } else {
      try {
        const createReq = await addService(values);
        let { data, status } = createReq;

        toast.success(data?.message);
        navigate("/dashboard/services");
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
      }
    }
  };

  // function for handling the change in the select box
  const onTypeChange = (val, index) => {
    if (val !== null) {
      setShowProperty((prevShowProperties) => {
        const updatedShowProperties = [...prevShowProperties];
       
        updatedShowProperties[index] = true;
        return updatedShowProperties;
      });
    } else {
      setShowProperty((prevShowProperties) => {
        const updatedShowProperties = [...prevShowProperties];
        updatedShowProperties[index] = false;
        return updatedShowProperties;
      });
    }
  };

  // function for fetching the records of service by id
  const fetchRecords = async () => {
    try {
      let resp = await getServiceById(id);
      let obj = resp.data?.responseData;
      console.log(obj, "objasdasd");
      setServices(obj);
      if (obj) {
        setShowProperty(obj.Providers.map((provider) => provider.name !== ""));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchRecords();
    }

    // console.log(initialValues,"initialValues")
    // console.log(initialValues?.providers?.length ,"initialValues?.providers?.length >")
    // if(initialValues?.providers?.length > 0){

    //   let serviceName = initialValues?.name
    //   let providerListing = initialValues?.providers?.map((provider) => provider?.name)

    //   console.log(providerListing,"providerListing")
      // determineServiceProviders(serviceName, providerListing, setServiceProviders);
    
  // }

  }, []);
  // console.log(showProperty, "shoProperty[index]");

  // console.log(initialValues, "initialValues")
  // console.log(SERVICE_PROVIDERS,"serviceProviders")

  const removeProvider = (index,formikProps) => {
    formikProps.setFieldValue(
      "providers",
      formikProps.values.providers.filter((_, i) => i !== index)
    );
    setShowProperty((prevShowProperties) => {
      const updatedShowProperties = [...prevShowProperties];
      updatedShowProperties.splice(index, 1);
      return updatedShowProperties;
    });
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {id ? (
                <h3 className="mb-sm-0 page-title">{_("EDIT_SERVICE")}</h3>
              ) : (
                <h3 className="mb-sm-0 page-title">{_("ADD_SERVICE")}</h3>
              )}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to={"/dashboard/services/"}>{_("SERVICES")}</Link>
                  </li>
                  <li className="breadcrumb-item active">{_("ADD")}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <h4 className="card-title">{_("SERVICES")}</h4>
                <p className="card-title-desc">{_("SERVICE_INFO")}</p>

                <Formik
                  enableReinitialize={true}
                  validationSchema={serviceValidationSchema}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                >
                  {(formikProps) => {
                    console.log(formikProps, "formikProps");
                    // console.log(formikProps?.errors,"formikProps?.errors")
                    return (
                      <Form>
                        <div className="mb-3">
                          <Input
                            name="name"
                            label={_("TITLE")}
                            placeholder={_("TITLE")}
                            type="text"
                            className="form-control"
                            errors={formikProps?.errors}
                          />
                        </div>
                        <FieldArray name="providers">
                          {({ insert, remove, push }) =>{ 
                            return(
                            <div className="">
                              {formikProps?.values.providers.length >= 1 &&
                                formikProps?.values.providers.map(
                                  (provider, index) => (
                                    <>
                                      <div
                                        className="container box-container"
                                        key={index}
                                      >
                                        <div className="col ">
                                          <div className="mb-3 ">
                                            <label className="form-label">
                                              Service Provider {index + 1}
                                            </label>
                                            <CustomSelect
                                              field={formikProps.getFieldProps(
                                                `providers.${index}.name`
                                              )}
                                              form={formikProps}
                                              options={SERVICE_PROVIDERS}
                                              placeholder="Select type..."
                                              isMulti={false}
                                              isClearable={true}
                                              onChangeProvider={(
                                                selectedProvider
                                              ) =>
                                                onTypeChange(
                                                  selectedProvider,
                                                  index
                                                )
                                              }
                                              // isDisabled={initialValues?.providers[index]?.name}

                                              classNamePrefix={"cstm_select"}
                                            />
                                            <ErrorMessage
                                              name={`providers.${index}.name`}
                                              component="div"
                                              className="error-msg"
                                            />
                                          </div>
                                        </div>
                                      
                                       
                                          <>
                                         
                                          {formikProps?.values.providers?.[index]?.name === "google-gemini" ? (
                                             <div className="col">
                                             <div className="mb-3">
                                               <Input
                                                 name={`providers.${index}.config.googleKey`}
                                                 label={_("GOOGLE_KEY")}
                                                 placeholder={_("GOOGLE_KEY")}
                                                 type="text"
                                                 className="form-control"
                                                 errors={formikProps?.errors}
                                               />
                                             </div>
                                           </div>
                                          ):(
                                            <>
                                            {showProperty[index] && (
                                              <>
                                                <div className="col">
                                                  <div className="mb-3">
                                                    <Input
                                                      name={`providers.${index}.config.key1`}
                                                      label={_("KEY1")}
                                                      placeholder={_("KEY1")}
                                                      type="text"
                                                      className="form-control"
                                                      errors={formikProps?.errors}
                                                    />
                                                  </div>
                                                </div>
    
                                                <div className="col">
                                                  <div className="mb-3">
                                                    <Input
                                                      name={`providers.${index}.config.key2`}
                                                      label={_("KEY2")}
                                                      placeholder={_("KEY2")}
                                                      type="text"
                                                      className="form-control"
                                                      errors={formikProps?.errors}
                                                    />
                                                  </div>
                                                </div>
    
                                                <div className="col">
                                                  <div className="mb-3">
                                                    <Input
                                                      name={`providers.${index}.config.textTranslation`}
                                                      label={_("TEXT_TRANSLATION")}
                                                      placeholder={_(
                                                        "TEXT_TRANSLATION"
                                                      )}
                                                      type="text"
                                                      className="form-control"
                                                      errors={formikProps?.errors}
                                                    />
                                                  </div>
                                                </div>
    
                                                <div className="col">
                                                  <div className="mb-3">
                                                    <Input
                                                      name={`providers.${index}.config.documentTranslation`}
                                                      label={_(
                                                        "DOCUMENT_TRANSLATION"
                                                      )}
                                                      placeholder={_(
                                                        "DOCUMENT_TRANSLATION"
                                                      )}
                                                      type="text"
                                                      className="form-control"
                                                      errors={formikProps?.errors}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="mb-3">
      <div className="f-in w-100 form-check">
        <Field
          component={CheckBox}
          name={`providers.${index}.isDefault`}
          label="Default"
        />
        <ErrorMessage
          name={`providers.${index}.isDefault`}
          component="div"
          className="error-msg"
        />
      </div>
    </div>
    
                                              </>
                                             
                                            )}
                                            </>
                                          )}
                                        </>
                                       <div className="col d-flex gap-2 justify-content-end mt-3">
                          {formikProps.values.providers.length > 1 && (
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => removeProvider(index,formikProps)}

                            >
                              Remove Provider
                            </button>
                          )}
                          
                        </div>


                                      </div>
                                     
                                    </>
                                  )
                                )}
                            </div>
                          
                          )}}
                        </FieldArray>
                        <div className="col d-flex gap-2 justify-content-end mt-3">
                          {/* {formikProps.values.providers.length > 1 && (
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                formikProps.setFieldValue(
                                  "providers",
                                  formikProps.values.providers.slice(0, -1)
                                );
                                setShowProperty((prevShowProperties) => {
                                  const updatedShowProperties = [
                                    ...prevShowProperties,
                                  ];
                                  // console.log(updatedShowProperties,"updatedShowProperties")
                                  updatedShowProperties.pop();
                                  return updatedShowProperties;
                                });
                              }}
                            >
                              Remove Provider
                            </button>
                          )} */}
                          <button
                            type="button"
                            className="btn btn-primary waves-effect btn-save waves-light"
                            onClick={() =>
                              formikProps.setFieldValue("providers", [
                                ...formikProps.values.providers,
                                {
                                  name: "",
                                  config: {
                                    key1: "",
                                    key2: "",
                                    textTranslation: "",
                                    documentTranslation: "",
                                  },
                                  isDefault: "",
                                },
                              ])
                            }
                          >
                            Add Provider
                          </button>
                        </div>
                        <div className="d-flex flex-wrap gap-2 action-btn-row">
                          <button
                            type="submit"
                            className={`btn btn-primary waves-effect btn-save waves-light`}
                          >
                            {_("SAVE")}
                          </button>
                          <div>
                            <Link
                              to="/dashboard/services/"
                              className="btn btn-secondary btn-cancel waves-effect"
                            >
                              {_("CANCEL")}
                            </Link>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddServiceFormik;
