import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { editService, getServiceById } from "../../actions/services";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import JoditEdior from "../../components/FormFields/JoditEditor";
import ImageUploaderField from "../../components/FormFields/ImageUploaderField";
import SingleSelectCheckbox from "../../components/FormFields/SingleSelectCheckbox";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";

function EditService() {
  // use hooks
  const { control, handleSubmit, getValues, setError, setValue, reset, formState: { isSubmitting } } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [postCategory, setPostCategory] = useState([]);
  const [date, setDate] = useState();
  const [getOnSite, setOnSite] = useState(0);
  const { catId, id } = useParams();

  // on form submissions
  const onSubmit = async (formData) => {
    // console.log(formData, "formData");
    if(formData?.isOnsite == 0 && formData?.isOnline == 0 && formData?.isQuotation == 0){
      toast.error(_('CHOOSE_ATLEAST_ONE_SERVICE'));
    } else {
      try {
        if(formData?.imageId?.id){
          formData = {...formData, 'imageId': formData?.imageId?.id}
        } else {
          delete(formData?.imageId)
        }

        formData = {...formData, id: id}
        formData = {...formData, categoryId: catId}

        if (formData?.isOnsite == 1) {
          formData = {...formData, isOnsite: 1}
        } else {
          formData = {...formData, isOnsite: 0}
        }

        if (formData?.isOnline == 1) {
          formData = {...formData, isOnline: 1}
        } else {
          formData = {...formData, isOnline: 0}
        }

        if (formData?.isQuotation == 1) {
          formData = {...formData, isQuotation: 1}
        } else {
          formData = {...formData, isQuotation: 0}
        }

        if (formData?.isOnsiteFixed == 1) {
          formData = {...formData, isOnsiteFixed: 1}
        } else {
          formData = {...formData, isOnsiteFixed: 0}
        }

        if (formData?.isOnsitePerHour == 1) {
          formData = {...formData, isOnsitePerHour: 1}
        } else {
          formData = {...formData, isOnsitePerHour: 0}
        }

        if (formData?.isOnsitePerSqft == 1) {
          formData = {...formData, isOnsitePerSqft: 1}
        } else {
          formData = {...formData, isOnsitePerSqft: 0}
        }

        await editService(formData);
        navigate('/dashboard/category/services/'+catId);
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_('NO_INTERNET'));
        }
      }
    }
  };

  const fetchRecords = useCallback(async () => {
    try {
      let resp = await getServiceById(id);
      let obj = resp?.data?.responseData;
      setValue("imageId", obj?.serviceImage)
      setValue("name", obj?.name)
      setValue("included", obj?.included)
      setValue("excluded", obj?.excluded)
      setValue("isOnsite", `${obj?.isOnsite}`)
      setValue("isOnline", `${obj?.isOnline}`)
      setValue("isQuotation", `${obj?.isQuotation}`)
      setValue("isOnsiteFixed", `${obj?.isOnsiteFixed}`)
      setValue("isOnsitePerHour", `${obj?.isOnsitePerHour}`)
      setValue("isOnsitePerSqft", `${obj?.isOnsitePerSqft}`)

      setOnSite(obj?.isOnsite)
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, setValue, id]);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords])

  
  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 font-size-18">{_('ADD_SERVICE')}</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={"/dashboard/category/services/"+catId}>{_('SERVICES')}</Link></li>
                    <li className="breadcrumb-item active">{_('ADD')}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">{_('SERVICES')}</h4>
                <p className="card-title-desc">{_('SERVICE_INFO')}</p>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                  
                  <div className="row">
                    <div className="mb-3">
                      <label className="form-label">{_('TITLE')}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_('TITLE')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('TITLE_REQUIRED') },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <SingleSelectCheckbox
                          formInWidth= "w-20"
                          name="isOnline"
                          control={control}
                          options={[
                            {
                              value: "1",
                              name: _('ONLINE_SERVICE'),
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <SingleSelectCheckbox
                          formInWidth= "w-20"
                          name="isQuotation"
                          control={control}
                          options={[
                            {
                              value: "1",
                              name: _('QUOTATION_SERVICE'),
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <SingleSelectCheckbox
                          formInWidth= "w-20"
                          name="isOnsite"
                          control={control}
                          onChange={() => setDate(new Date())}
                          options={[
                            {
                              value: "1",
                              name: _('ONSITE_SERVICE'),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                  
                  {
                    ((getValues("isOnsite")?.length == 1) && getOnSite == 1)
                    ?
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <SingleSelectCheckbox
                            formInWidth= "w-20"
                            name="isOnsiteFixed"
                            control={control}
                            options={[
                              {
                                value: "1",
                                name: _('ONSITE_FIXED_SERVICE'),
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <SingleSelectCheckbox
                            formInWidth= "w-20"
                            name="isOnsitePerHour"
                            control={control}
                            options={[
                              {
                                value: "1",
                                name: _('ONSITE_PER_HOUR_SERVICE'),
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <SingleSelectCheckbox
                            formInWidth= "w-20"
                            name="isOnsitePerSqft"
                            control={control}
                            options={[
                              {
                                value: "1",
                                name: _('ONSITE_PER_SQUAR_FEET_SERVICE'),
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }

                  <div className="row">
                    <div className="mb-3">
                      <JoditEdior
                        control={control}
                        name={"included"}
                        label={_('INCLUDED')}
                        errorClass="error-msg"
                        rules={{
                          required: {
                            value: true,
                            message: _('INCLUDED_REQUIRED'),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3">
                      <JoditEdior
                        control={control}
                        name={"excluded"}
                        label={_('NOT_INCLUDED')}
                        errorClass="error-msg"
                        rules={{
                          required: {
                            value: true,
                            message: _('EXCLUDED_REQUIRED'),
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3">
                      <ImageUploaderField
                        control={control}
                        label="Image"
                        name="imageId"
                        uploadType="image"
                        setValue={setValue}
                        changeLabel={"image"}
                        uploadText="Upload image"
                        extensionTypes={["jpeg", "jpg", "png"]}
                        rules={{
                          required: {
                            value: false,
                            message: "Image is required",
                          },
                        }}
                      />
                    </div>
                  </div>


                  <div className="d-flex flex-wrap gap-2">
                    <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect waves-light ${isSubmitting && "btn-loader"}`}>
                      {_('SAVE')}
                    </button>
                    <Link to={"/dashboard/category/services/"+catId} className="btn btn-secondary waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditService;
