import React from "react";

const CheckBox = ({ field, form, label }) => {
  const handleChange = () => {
    const { name } = field;
    const { setFieldValue, values } = form;

    // If the current checkbox is not already selected, set it to true
    // and set other checkboxes in the array to false
    if (!field.value) {
      setFieldValue(name, true);
      values.providers.forEach((provider, index) => {
        if (index !== parseInt(name.split(".")[1])) {
          setFieldValue(`providers.${index}.isDefault`, false);
        }
      });
    }
  };

  return (
    <div className="form-check">
      <input
        type="checkbox"
        className="form-check-input"
        id={field.name}
        checked={field.value}
        onChange={handleChange}
      />
      <label className="form-check-label" htmlFor={field.name}>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
