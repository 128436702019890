import { EMAIL_TEMPLATE, EMAIL_TEMPLATE_DETAIL, DELETE_EMAIL_TEMPLATE, EMAIL_TEMPLATE_STATUS} from "../actions/email";

const initialState = {
  emailTemplateListing: null,
  emailTemplateDetail: null,
};

const email = (state = initialState, action) => {


  switch (action.type) {
    case EMAIL_TEMPLATE:
      return {
        ...state,
        emailTemplateListing: action.payload
      };
    break;
    case EMAIL_TEMPLATE_DETAIL:
      return {
        ...state,
        emailTemplateDetail: action.payload
      };
    break;
    case DELETE_EMAIL_TEMPLATE:
      const data = { ...state.emailTemplateListing };
      let index = data?.data?.findIndex((item) => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        emailTemplateListing: data,
      };
    break;
    case EMAIL_TEMPLATE_STATUS:
      let template = { ...state.emailTemplateListing };
      let endex = template?.data?.findIndex((item) => item.id === action?.payload?.id);
      template.data[endex] = {...template.data[endex], ...action.payload}
      return {
        ...state,
        emailTemplateListing: {...template},
      };
    break;

    default:
  }
  return state;
}
export default email;