import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
  title: Yup.string().required("Title is required"),
  description: Yup.mixed().required("Description is required"),
  // options: Yup.array().of(
  //   Yup.object().shape({
  //     // title: Yup.string().required("Title is required"),
  //     weightage: Yup.number()
  //       // .required("Score is required")
  //       .min(0, "Score must be at least 0"),

  //       // attributes: Yup.object().shape({
  //       //   min: Yup.number().required('Min value is required'),
  //       //   max: Yup.number()
  //       //   .required('Max value is required')
  //       //   .when(['min'], (min, schema) => schema.test({
  //       //     test: max => max >= min,
  //       //     message: 'Max should be greater than or equal to Min',
  //       //   })),
  //       // }),

  //   })

  // ),
  options: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Title is required"),
      score: Yup.number()
        .required("Score is required")
        .min(0, "Score must be at least 0"),
    })
  ),
});

export const translateSchema = Yup.object().shape({
  apikey: Yup.string().required("Api Key is required"),

  sourceLanguage: Yup.string().required("Title is required"),
  text: Yup.array().of(
    Yup.object().shape({
      content: Yup.string().required("Content is required"),
    })
  ),
});
export const serviceSchema = Yup.object().shape({
  apikey: Yup.string().required("Api Key is required"),
  text: Yup.string().required("Content is required"),
});
export const resEveSchema = Yup.object().shape({
  apikey: Yup.string().required("Api Key is required"),

  question: Yup.string().required("Question is required"),
  answer: Yup.string().required("Answer is required"),
});

export const serviceValidationSchema = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  providers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Service provider is required"),
      config: Yup.object().shape({}),
    })
  ),
});

export function removeEmptyStringKeys(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === "" || obj[key] == null) {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        removeEmptyStringKeys(obj[key]);
      }
    }
  }
}
