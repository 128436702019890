import axios from "axios";
import { BASE_URL } from "../constants";

export const KEY_LIST = "KEY_LIST";
export const DELETE_KEY = "DELETE_KEY";
export const KEY_STATUS = "KEY_STATUS";
export const FETCH_KEY_LIST = "FETCH_KEY_LIST";

//category crud
export const getKeysListing = (props) => {
  return axios({
    method: "GET",
    params: props,
    url: `/keys`,
  });
};
export function getServiceById(id) {
  return axios({
    method: "GET",
    // params: payload,
    url: "/service/" + id,
  });
}
export const generateKey = (props) => {
  return axios({
    method: "POST",
    data: props,
    url: "/generate",
  });
};
export function editService(payload, id) {
  return axios({
    method: "PATCH",
    data: payload,
    url: "/services",
  });
}
export function deleteService(payload) {
  return axios({
    method: "DELETE",
    data: payload,
    url: "/services",
  });
}
export function updateServiceStatus(payload) {
  return axios({
    method: "PATCH",
    data: payload,
    url: "/services",
  });
}

export const createPlayground = (props, key, service) => {
  const encodedService = encodeURIComponent(service.replace(/\s+/g, ""));
  console.log(`encodedService**`, encodedService);
  return axios({
    headers: {
      "Content-Type": "application/json",
      apikey: `${key}`,
      language: "en",
      accept: "application/json",
      timezone: "UTC",
      connection: "keep-alive",
    },

    method: "POST",
    data: props,
    url: `/${encodedService}`,
  });
};


export const getVideoService = (props, key,)=>{
  
  return axios({
    headers: {
    "Content-Type": "application/json",
      apikey: `${key}`,
    
    },
    method: "POST",
    data:props,
    url: "/createConference",
  });
}