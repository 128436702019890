import { useEffect } from "react";
import Select from "react-select";

const CustomSelect = ({
  field,
  form,
  options,
  isMulti = false,
  placeholder = "Select",
  defaultValue,
  onChangeCallback,
  onChangeType,
  selectedType,
  optionIndex,
  onChangeLinkedQuestion,
  isClearable,
  linkedQuestionRef,
  onChangeDevice,
  classNamePrefix,
  onChangeProvider,
  isDisabled
}) => {

  useEffect(() => {
  }, [field?.value])

  function onChange(option) {
    console.log(option,"optionasd")
    console.log(options,"optionasd2")

    form.setFieldValue(
      field.name,
      option ? option.map((item) => item.value) : []
    );
    
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) =>  field?.value?.indexOf(option.value) >= 0)
        // console.log(field?.value?.indexOf(option.value) >= 0,option, "Heyoooption") )
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };
  // console.log(defaultValue,"defaultValue")
  if (!isMulti) {
    console.log(options, "options");
    console.log(field, "options2");
    console.log(field, "Ref")
    return (
    
      <Select
        options={options}
        // name={field.name}
        ref={linkedQuestionRef}
        value={
          options
            ? options.find((option) => option.value === field.value)
            : null
        }
        classNamePrefix={classNamePrefix}

        onChange={(option) => {
          console.log(option,optionIndex, "HiOption")
          // const updatedOptions = options.map((item) => (
          //   // console.log(item,"itme")
            
          //   {
          //     ...item,
          //     deviceId:item.value,
          //   title: item.label
          // }
          // ));
         

          form.setFieldValue(field.name, !option ? null : option?.value);
          
          if(onChangeCallback){
            // onChangeCallback(form, option.value);
            onChangeCallback(form, !option ? null : option?.value)
          }
          if(onChangeProvider){
            onChangeProvider(!option ? null : option?.value)
          }
          if(onChangeType){
            onChangeType(`${optionIndex}_${new Date().getTime()}`, option.value)
           
          }
          if(onChangeLinkedQuestion){
            onChangeLinkedQuestion(form, !option ? null : option?.value)
          }
          if(onChangeDevice){
            
            onChangeDevice(form,  !option ? null : option?.value)
          }
        }
        
      }
      
      isOptionDisabled={(option) => {
        if (selectedType !== null && selectedType !== undefined)
          return Object.values(selectedType).includes(option.value) && (Object.keys(selectedType).find(key => key ?             key.startsWith(optionIndex) : false) !== optionIndex)
        return false
      }}
        onBlur={field.onBlur}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
    );
  } else {
    return (
      <Select
        className="react-select-container"
        classNamePrefix={classNamePrefix}
        name={field.name}
        value={getValue()}
        onChange={onChange}
        options={options}
        isMulti={true}
        defaultValue={defaultValue}
        placeholder={placeholder}
        isClearable={isClearable}



      />
    );
  }
};

export default CustomSelect;
