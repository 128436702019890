import moment from "moment";

export function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (
      data[key] === "" ||
      data[key] == null ||
      data[key]?.length === 0 ||
      data[key] === false
    ) {
      delete data[key];
    }
  });
  return data;
}

export function clearSession() {
  localStorage.clear();
  // redirect on root
  window.location = "/";
}

export function isAuthenticated(token) {
  if (token && token != undefined) {
    return true;
  }
  return false;
}

export function authentication() {
  // With auth token
  if (typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null)
    return true;
  else
    return false;
};

export function firstLetterCapital(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function toShortString(str, len = 30) {
  if (str?.length >= len) {
    return str.substring(0, len) + " ...";
  } else {
    return str;
  }
}

export const getDates = (dateCode) => {
  let startDate;
  const utcOffset = moment().utcOffset();
  let endDate = moment().add(parseInt(utcOffset), "minutes");
  switch (dateCode) {
    case "TODAY":
      startDate = moment().startOf("day").add(parseInt(utcOffset), "minutes");
      break;
    case "YESTERDAY":
      startDate = moment()
        .startOf("day")
        .add(-1, "days")
        .add(parseInt(utcOffset), "minutes");
      endDate = moment()
        .startOf("day")
        .add(-1, "seconds")
        .add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_7_DAYS":
      startDate = moment().add(-7, "days").add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_30_DAYS":
      startDate = moment().add(-30, "days").add(parseInt(utcOffset), "minutes");
      break;
    case "THIS_MONTH":
      startDate = moment().startOf("month").add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_MONTH":
      startDate = moment()
        .startOf("month")
        .add(-1, "months")
        .add(parseInt(utcOffset), "minutes");
      endDate = moment()
        .startOf("month")
        .add(-1, "seconds")
        .add(parseInt(utcOffset), "minutes");
      break;
    case "LAST_YEAR":
      startDate = moment().startOf("year").add(parseInt(utcOffset), "minutes");
      endDate = moment().endOf("year").add(parseInt(utcOffset), "minutes");
      break;
    default:
      break;
  }

  return { start: startDate.utc().format("YYYY-MM-DD HH:mm:ss"), end: endDate.utc().format("YYYY-MM-DD HH:mm:ss") };
};


// service get function
// export const determineServiceProviders = (serviceName, providerListing, setServiceProviders) => {
//   console.log(providerListing.includes("azure"),"providerListing.includes('azure')")
//   switch (serviceName?.toLowerCase().trim()) {
//     case "translation":
//       if (providerListing.includes("azure")) {
//         setServiceProviders([
//           {
//             label: "Azure",
//             value: "azure",
//           },
//         ]);
//         break;
//       } else if (providerListing.includes("google gemini")) {
//         setServiceProviders([
//           {
//             label: "Google Gemini",
//             value: "google gemini",
//           },
//         ]);
//         break;
//       }
//       break;

//     case "sentiment analysis":
//       if (providerListing.includes("azure")) {
//         setServiceProviders([
//           {
//             label: "Google Gemini",
//             value: "google gemini",
//           },
//         ]);
//         break;
//       }
//       break;

//     case "Response evaluation":
//       if (providerListing.includes("azure")) {
//         setServiceProviders([
//           {
//             label: "Google Gemini",
//             value: "google gemini",
//           },
//         ]);
//         break;
//       }
//       break;

//     default:
//       setServiceProviders([
//         {
//           label: "Azure",
//           value: "azure",
//         },
//       ]);
//       break;
//   }
// };
