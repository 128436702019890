import { NavLink, useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { _ } from "../../locale";

function LeftNavigation() {
  const [managePanel, setManagePanel] = useState(false);
  const [manageAccessPanel, setAccessPanel] = useState(false);
  const [manageKeyPanel, setKeyPanel] = useState(false);
  const location = useLocation();


  // handle management tab
  const handleManagement = () => {
    setManagePanel((prev) => !prev);
  };
  const handleKeyManagement = () => {
    setKeyPanel((prev) => !prev);
  };
  const handleAccessManagement = () => {
    setAccessPanel((prev) => !prev);
  };

  const isServicesActive = location.pathname.includes("/dashboard/services") || location.pathname.includes("/dashboard/accounts");

  const isKeysActive = location.pathname.includes("/dashboard/keys");

  const isActive = (path) => {
    return location.pathname === path
  };

  
  const AccessAdmin = localStorage.getItem("hasAllAccess");

  useEffect(() => {
    setManagePanel(isServicesActive);
  }, [isServicesActive]);


  useEffect(() => {
    setKeyPanel(isKeysActive);
  }, [isKeysActive]);

  // console.log(AccessAdmin,"AccessAdmin")
  // console.log(location.pathname , "Pathanmae")
  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title" key="t-menu">
              {_("MENU")}
            </li> */}
            <li className="dashboard">
              <Link
                className={
                  location?.pathname  === "/dashboard" ? "waves-effect active" : "waves-effect"
                }
                activeClassName="sdfsdfsdf"
                to="/dashboard"
              >
                {/* <i class='fas fa-user-circle'></i> */}


                <span className="dashboardMneu">
                  <i className="menu-icon dashboard-icon"></i>
                  <span key="t-customers">{_("DASHBOARD")} </span>
                </span>
              </Link>
            </li>
            {/* <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "waves-effect active" : "waves-effect"
                }
                to="/dashboard/"
              >
                <i className="bx bx-home-circle"></i>
                <span key="t-dashboards">{_("DASHBOARD")} </span>
              </NavLink>
            </li> */}

        {AccessAdmin == 1 && (
            <li className="user-management">
               
                <Link
                  className={isServicesActive
                      ? "has-arrow waves-effect active"
                      : "has-arrow waves-effect"
                  }
                  
                  to="/dashboard/services"
                  onClick={handleManagement}
                >
                  <span className="dashboardMneu">
                  <i className="menu-icon user-management"></i>

                    <span key="t-ecommerce">{_("MANAGEMENT")}</span>
                  </span>
                </Link>
               
                {managePanel === true ? (
                  <ul
                    className={
                      managePanel === true
                        ? "sub-menu mm-collapse mm-show"
                        : "sub-menu mm-collapse"
                    }
                  >
                  
                    <li>
                      <Link
                        className={ location.pathname === "/dashboard/services" ? "active" : ""}
                        to="/dashboard/services"
                      >
                        {_("SERVICES")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className={location.pathname === "/dashboard/accounts" ? "active" : ""}
                        to="/dashboard/accounts"
                      >
                        {_("ACCOUNTS")}
                      </Link>
                    </li> */}
                    
                  </ul>
                ) : null}
              
            </li>
            )}
            {/* <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "waves-effect active" : "waves-effect"
                }
                to="/dashboard/service-provider"
              >
                <i className="bx bx-user-circle"></i>
                <span key="t-dashboards">{_("SERVICE_PROVIDER")} </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "waves-effect active" : "waves-effect"
                }
                to="/dashboard/user"
              >
                <i className="bx bxs-user-detail"></i>
                <span key="t-dashboards">{_("USERS")} </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "has-arrow waves-effect active"
                    : "has-arrow waves-effect"
                }
                to="#"
                onClick={handleAccessManagement}
              >
                <i className="bx bx-briefcase-alt"></i>
                <span key="t-ecommerce">{_("ACCESS_CONTROL")}</span>
              </NavLink>

              {manageAccessPanel === true ? (
                <ul
                  className={
                    manageAccessPanel === true
                      ? "sub-menu mm-collapse mm-show"
                      : "sub-menu mm-collapse"
                  }
                >
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/dashboard/acl/role"
                    >
                      {_("ROLE")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/dashboard/acl/staff"
                    >
                      {_("STAFF")}
                    </NavLink>
                  </li>
                </ul>
              ) : null}
            </li> */}
             <li className="manage-keys">
               
               <NavLink
                 className={location.pathname === "/dashboard/keys"
                     ? "has-arrow waves-effect active"
                     : "has-arrow waves-effect"
                 }
                 to="/dashboard/keys"
                 onClick={handleKeyManagement}
               >
                 <span className="dashboardMneu">
                  <i className="menu-icon manage-keys"></i>

                   <span key="t-ecommerce">{_("MANAGE_KEYS")}</span>
                 </span>
               </NavLink>
              
               {manageKeyPanel === true ? (
                 <ul
                   className={
                    manageKeyPanel === true
                       ? "sub-menu mm-collapse mm-show"
                       : "sub-menu mm-collapse"
                   }
                 >
                 
                   <li>
                     <NavLink
                       className={({ isActive }) => (isActive ? "active" : "")}
                       to="/dashboard/keys"
                     >
                        {_("KEYS")}
                     </NavLink>
                   </li>
                  
                   
                 </ul>
               ) : null}
             
           </li>
            {/* <li className="manage-key">
             
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "has-arrow waves-effect active"
                      : "has-arrow waves-effect"
                  }
                  onClick={handleKeyManagement}
                  href="#"
                >
                  <span className="dashboardMneu">
                    <i className="bx bx-briefcase-alt"></i>
                    <span key="t-ecommerce">{_("MANAGE_KEYS")}</span>
                  </span>
                </NavLink>

                {manageKeyPanel === true ? (
                  <ul
                    className={
                      manageKeyPanel === true
                        ? "sub-menu mm-collapse mm-show"
                        : "sub-menu mm-collapse"
                    }
                  >
                    <li>
                      <NavLink
                        className={({ isActive }) => (isActive ? "active" : "")}
                        to="/dashboard/keys"
                      >
                        {_("KEYS")}
                      </NavLink>
                    </li>
                    
                  </ul>
                ) : null}
            </li> */}
            {/* <li>
                            <NavLink className={({ isActive }) => (isActive ? 'waves-effect active' : 'waves-effect')} to='/dashboard/homeScore'>
                                <i className="bx bx-home-circle"></i>
                                <span key="t-dashboards">{_('HOMESCORE')} </span>
                            </NavLink>
                        </li> */}
 

            {/* <li className="playgrounds">
              
                <NavLink
                  className={
                    isActive('/dashboard/playgrounds') ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/playgrounds"
                >
                  <span className="dashboardMneu">
                  <i className="menu-icon playground"></i>

                    <span key="t-dashboards">{_("PLAYGROUNDS")} </span>
                  </span>
                </NavLink>
              
            </li> */}
            {/* <li className="settings">
              
                <NavLink
                  className={
                    isActive('/dashboard/settings') ? "waves-effect active" : "waves-effect"
                  }
                  to="/dashboard/settings"
                >
                  <span className="dashboardMneu">
                  <i className="menu-icon settings"></i>

                  <span key="t-dashboards">{_("SETTINGS")} </span>
                  </span>
                </NavLink>
            </li> */}
            
 
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LeftNavigation;
