import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useEffect, useState } from "react";
import {_} from "../../locale";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { DELETE_SERVICE, deleteService } from "../../actions/services";

function ManagePageSlide({slideData, index, srno}) {

  //   local variables
  const [status, setStatus] = useState(slideData?.createdBy?.status);
  const dispatch = useDispatch();

  const deleteRecord = () => {
    confirmAlert({
      title: 'Confirm',
      message: _('CONFIRM_DELETE'),
      buttons: [
        {
          label: _('YES'),
          onClick: async () => {
            try {
              await deleteService( slideData?.id);
              dispatch({ type: DELETE_SERVICE, id: slideData?.id });
              // toast.info("Category type has been deleted successfully");
            } catch (error) {
              console.log(error);
            }
          }
        },
        {
          label: _('NO')
        }
      ]
    });
  };

  

  useEffect(() => {
    setStatus(slideData?.createdBy?.status)
  }, [slideData?.createdBy?.status])
  


  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td><p className="font-size-14 mb-1">{slideData?.name ?? null}</p></td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{moment(slideData?.createdAt).format('lll') ?? null}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.createdBy?.name ?? null}</p>
      </td>
      <td>
        <h5 class="font-size-14 mb-1"><a href="javascript: void(0);" class="text-dark">{slideData?.updatedAt ? moment(slideData?.updatedAt).format('lll') : moment(slideData?.createdAt).format('ll')}</a></h5>
        <p class="text-muted mb-0">{_('BY')} {slideData?.updatedBy?.name ? slideData?.updatedBy?.name : slideData?.author?.name }</p>
      </td>
      <td>
        <a href="javascript:void(0)" >
        {
          status
          ?
          <span className="badge bg-success">{_('ACTIVE')}</span>
          :
          <span className="badge bg-danger">{_('NOT_ACTIVE')}</span>  
        }
        </a>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/service/${slideData.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          {/* <li>
            <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
          </li> */}
        </ul>
      </td>
  </tr>
  );
}

export default ManagePageSlide;
