import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { updateEmailTemplateStatus, deleteEmailTemplate, DELETE_EMAIL_TEMPLATE, EMAIL_TEMPLATE_STATUS } from "../../actions/email";
import { useEffect, useState } from "react";
import {_} from "../../locale";
import { useDispatch } from "react-redux";

function ManageAccountSlide({slideData, index, srno}) {
  //   local variables
  const [status, setStatus] = useState(slideData?.status);
  const dispatch = useDispatch();

  const deleteRecord = () => {
    confirmAlert({
      title: 'Confirm',
      message: _('CONFIRM_DELETE'),
      buttons: [
        {
          label: _('YES'),
          onClick: async () => {
            try {
              await deleteEmailTemplate(slideData?.id);
              dispatch({ type: DELETE_EMAIL_TEMPLATE, id: slideData?.id });
              // toast.info("Category type has been deleted successfully");
            } catch (error) {
              console.log(error);
            }
          }
        },
        {
          label: _('NO')
        }
      ]
    });
  };


  const updateStatus = () => {
    let status = (slideData.status == '1' ? false : true);
    let chkStatus = (slideData.status == '1' ? 0 : 1);
    confirmAlert({
      title: 'Confirm',
      message: slideData.status == '1' ? _('CONFIRM_DEACTIVATE') : _('CONFIRM_ACTIVATE'),
      buttons: [
        {
          label: _('YES'),
          onClick: async () => {
            try {
              const response = await updateEmailTemplateStatus({status} ,slideData?.id);
              dispatch({ type: EMAIL_TEMPLATE_STATUS, payload: response?.data?.responseData });
            } catch (error) {
              console.log(error);
            }
          }
        },
        {
          label: _('NO')
        }
      ]
    });
  };

  useEffect(() => {
    setStatus(slideData.status)
  }, [slideData.status])
  
  return (
    <tr>
      <td>{parseInt(srno + parseInt(index + 1))}</td>
      <td><p className="font-size-14 mb-1">{slideData?.code ?? null}</p></td>
      <td>{slideData?.title ?? null}</td>
      <td>{slideData?.subject ?? null}</td>
      <td>
        <a href="javascript:void(0)" onClick={updateStatus}>
        {
          status
          ?
          <span class="badge bg-success">{_('ACTIVE')}</span>
          :
          <span class="badge bg-danger">{_('NOT_ACTIVE')}</span>  
        }
        </a>
      </td>
      <td>
        <ul className="list-unstyled hstack gap-1 mb-0">
          <li>
              <Link to={`/dashboard/account/${slideData.id}/edit`} className="btn btn-sm btn-soft-info" title="Edit"><i className="mdi mdi-pencil-outline"></i></Link>
          </li>
          <li>
            <a href="javascript:void(0)" onClick={deleteRecord} className="btn btn-sm btn-soft-danger" title="Delete"><i className="mdi mdi-delete-outline"></i></a>
          </li>
        </ul>
      </td>
  </tr>
  );
}

export default ManageAccountSlide;
