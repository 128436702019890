import { Modal } from "react-bootstrap";

function CodeModal({ show, onHide, apiKey }) {
  console.log(show, onHide, apiKey, "HeyModalDetails");
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="successful-modal"
      >
        <Modal.Body className="requestModal">
          <div className="modal-innder">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                How to Send Request
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onHide}
              ></button>
            </div>
            <div className="modal-body">
              <div className="">
                <div className="requestModal__method">
                  <h5 className="request_title">Method : {"  "}</h5>
                  {"  "} <p className="">POST</p>
                </div>
                <div className="requestModal__method">
                  <h5 className="request_title">URL : </h5>
                  <p> http://54.176.169.179:3031/translate</p>
                </div>
              </div>
              <div className="requestModal__method">
                <h5 className="request_title">apikey (Header) : </h5>
                <p> {apiKey}</p>
              </div>
              <div className="requestModal__method body_request">
                <h5 className="request_title">Body : </h5>
                <p className="json_text">
                  <pre>
                    {`
{
  "text": ["string"],
  "sourceLanguage": "string",
  "targetLanguages": ["string"]
}
`}
                  </pre>
                </p>
              </div>
              <div className="requestModal__method body_request">
                <h5 className="request_title">Response : </h5>
                <p className="json_text">
                  <pre>
                    {`
{
    "message": "REQUEST_PROCESSED_SUCCESSFULLY",
    "responseData": [
        {
            "text": "What is your name?",
            "sourceLanguage": "en",
            "targetLanguages": [
                "hi"
            ],
            "translations": [
                {
                    "lang": "hi",
                    "translation": "आपका नाम क्या है?"
                },
               
            ]
        },
        
    ]
}
`}
                  </pre>
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CodeModal;
