import { useController } from "react-hook-form";
import Select from "react-select";
import {forwardRef} from "react";
import { errorType } from "../../constants";

const ReactSelectField = forwardRef(function ReactSelectField({
  name,
  control,
  rules,
  defaultValue,
  label,
  multi,
  prefixClass,
  containerClass,
  optionValue,
  optionLabel,
  readOnly,
  options,
  onSelect,
  placeholder,
  normalize,
  callback,
  clear,
  isClearable,
  

}, ref) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });


  // handle value on change
  const handleChange = (value) => {
    // send value to onChange function

    if (multi) {
        let multiData = value.map((_value) =>
          optionValue !== undefined ? _value[optionValue] : _value?.value
        );
        field.onChange(multiData);
    }else{

      // console.log('vvvv', value);
      if(callback){
        callback(value);  
      }
      

      if (onSelect) {
        if (normalize) onSelect(normalize(value?.value));
        else onSelect(value?.value);
      }
  
      // send value to hook form
      if (normalize) field.onChange(normalize(value?.value));
      else field.onChange(value?.value);
  
    }
  };

  // handling value according to selected one
  const handleValue = () => {
    if (field?.value !== "") {
      if (multi) {
        return options?.filter((c) =>
          field?.value?.includes?.(
            optionValue !== undefined ? c[optionValue] : c.value
          )
        );
      } else {
        return options?.find((c) =>
          optionValue
            ? c[optionValue] === field?.value
            : c.value === field?.value
        );
      }
    } else {
      return "";
    }
  };

  return (
    <>
      
              <Select
                ref={ref}
                inputRef={field.ref}
                isDisabled={readOnly}
                value={handleValue()}
                onChange={(val) => handleChange(val)}
                options={options}
                placeholder={placeholder ? placeholder : label}
                isMulti={multi}
                isClearable={isClearable}
                getOptionLabel={(opt) => opt[optionLabel]}
                getOptionValue={(opt) => opt[optionValue]}
                className={containerClass || ""}
                classNamePrefix={prefixClass || ""}
                // isClearable={clear}
              />
            
            {errorType?.map((type) => {
              if (error?.type === type && error?.message !== "")
                return <span key={type} className="error">{error?.message}</span>;
            })}
          
    </>
  );
})

export default ReactSelectField;
