import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PreviewSkelton = ({count, translationLength}) => {
  const items = new Array(count).fill(0);
  const translationItems = new Array(translationLength).fill(0);
  return (
    <>
    {
      items.map((item, index) => (
      //  create skeltion for preview the below is html of preview
      
        <>
        <div className='d-flex gap-4'>
         <Skeleton width={100} height={20} />
         <Skeleton width={300} height={20} />
         </div>
         <div className='d-flex gap-4 mt-4'>
         <Skeleton width={100} height={20} />
         <Skeleton width={300} height={40} />
         </div>
         <Skeleton width={100} height={20} />
        {translationItems.map((item, index) => (
          <div className='d-flex gap-4 mt-4'>
          <Skeleton width={100} height={20} />
          <Skeleton width={300} height={40} />
          </div>
        
        ))}
          
         

         </>
      ))
    }
    </>
  );
};

export default PreviewSkelton;