// API end points
export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "http://localhost:3000";

export const PAGE_LIMIT = 20;
export const limit = 20; 
export const imagesToAccept = [".jpg", ".jpeg", ".png", ".gif", ".svg"];
export const VIDEO_MAX_SIZE = 8;
export const CURRENCY_SYMBOL = '$';
// Field validations
export const errorType = [
  "manual",
  "required",
  "pattern",
  "validate",
  "minLength",
  "maxLength",
  "max",
  "min",
  "positive",
  "lessThanTen",
  "greaterThan",
  "checkUrl",
];

export const POST_TYPE = [
  { 'label': 'Post', 'value': 'post'},
  { 'label': 'Page', 'value': 'page'}
];

export const DOC_STATUS_ACTION = [
  { 'label': 'Approved', 'value': 1},
  { 'label': 'Rejected', 'value': 2}
];


export const DOCUMENT_TYPE = [
  { 'label': 'Agency', 'value': 1},
  { 'label': 'Freelancer', 'value': 2},
  { 'label': 'Category/Service', 'value': 3}
];

export const SERVICE_ORIGIN = [
  { 'label': 'Online', 'value': 1},
  { 'label': 'Onsight', 'value': 2},
  { 'label': 'Quotation', 'value': 3}
];

export const ATTRIBUTE_TYPE = [
  { 'label': 'Hourly', 'value': 0},
  { 'label': 'Fixed', 'value': 1},
  { 'label': 'Sqft', 'value': 2}
];

export const DEFAULT_LONGITUDE = "-73.935242";
export const DEFAULT_LATITUDE = "40.730610";


export const CONFIG = {
  deviceType: 'WEB',
  contentLanguage: 'en',
}

export const SUBSCRIPTION_PLAN = [
  { 'label': 'razorpay', 'value': 'razorpay'}
];
