import { CSSProperties, useState } from "react";
import {
  AgoraRTCProvider,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useRemoteAudioTracks,
  useRemoteUsers,
  RemoteUser,
  LocalVideoTrack,
  useClientEvent,
  useVolumeLevel
} from "agora-rtc-react";
import Controls from "./Controls";
export default function Videos(props) {
  const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID
  const { AppID, channelName, token } = props;
  console.log(token, "this is token hhhhh")
  console.log(channelName, "this is channel name hhhhh")
  console.log(AppID, "this is app ID hhhhh")
  const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack();
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);
 
  const client = useRTCClient();
  useClientEvent(client, "user-published", (user) => {
    console.log(user);
  });

  usePublish([localMicrophoneTrack, localCameraTrack]);
  console.log({
    appid: AGORA_APP_ID,
    channel: channelName,
    tokens: token === "" ? null : token,
    uid: AppID
  }, "hhhhhhhtt")
  useJoin({
    appid: AGORA_APP_ID,
    channel: channelName,
    token: token === "" ? null : token,
    uid: AppID
  });
  
  audioTracks.map((track) => track.play());

  const deviceLoading = isLoadingMic || isLoadingCam;
  if (deviceLoading) return <div style={styles.grid}>Loading devices...</div>;

  const deviceUnavailable = !localCameraTrack || !localMicrophoneTrack;
  if (deviceUnavailable) return <div style={styles.grid}>Please allow camera and microphone permissions</div>;
 



  return (
    <>
      <div style={{ ...styles.grid, ...returnGrid(remoteUsers) }}>
       
        <LocalVideoTrack track={localCameraTrack} play={true} style={styles.gridCell} />
        {remoteUsers.map((user) => (
          <RemoteUser user={user} style={styles.gridCell} />
        ))}
      </div>
      <br />
      <Controls localMicrophoneTrack={localMicrophoneTrack} localCameraTrack={localCameraTrack} />
    </>
  );
}






const returnGrid = (remoteUsers) => {
  return {
    gridTemplateColumns:
      remoteUsers.length > 8
        ? unit.repeat(4)
        : remoteUsers.length > 3
          ? unit.repeat(3)
          : remoteUsers.length > 0
            ? unit.repeat(2)
            : unit,
  };
};
const unit = "minmax(0, 1fr) ";
const styles = {
  grid: {
    width: "100%",
    height: "100%",
    display: "grid",
    position:"absolute"
  },
  gridCell: { height: "100%", width: "100%" },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    width: "50%",
    justifyContent: "space-evenly",
  },
};