import { useCallback, useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addService, editService, getServiceById } from "../../actions/services";
import {_} from "../../locale";
import Inputfield from "../../components/FormFields/InputField";
import InputTextAreaField from "../../components/FormFields/InputTextAreaField";
import ReactSelectField from "../../components/FormFields/ReactSelectField";
import JoditEdior from "../../components/FormFields/JoditEditor";
import ImageUploaderField from "../../components/FormFields/ImageUploaderField";
import SingleSelectCheckbox from "../../components/FormFields/SingleSelectCheckbox";
import { toast } from "react-hot-toast";
import SingleCheckBox from "../../components/common/SingleCheckBox";

function AddService() {
  // use hooks
  const {control, handleSubmit, getValues, setValue, formState: { isSubmitting }, } = useForm();
  const navigate = useNavigate();
   const { id } = useParams(); 
  // http://localhost:3022/dashboard/service/2/edit
  const [showProperty, setShowProperty] = useState(false);
  const SERVICE_PROVIDERS = [
    { 'label': 'Azure', 'value': "azure"},
  ];
  // on form submissions
  const onSubmit = async (formData) => {
    let payload = {
      name: formData.name,
      providers:[
        {
        name: formData.providername,
        config:{
          key1: formData.key1,
          key2: formData.key2,
          textTranslation: formData.textTranslation,
          documentTranslation: formData.documentTranslation,
        },
        isDefault: true,
    
      }
     ]
    
    }
    // console.log(payload,"payloadpayloadpayload")
return;
    if(id){
      try {
        const createReq = await editService(payload,id);
        let { data, status } = createReq;
        
          toast.success(data?.message);
          navigate('/dashboard/services');
      } catch ({ response, request }) {
        if (response) {
          toast.error(response?.data?.message);
        } else if (request) {
          toast.error(_("NO_INTERNET"));
        }
      }
    }else {

    try {
      const createReq = await addService(payload);
      let { data, status } = createReq;
      
        toast.success(data?.message);
        navigate('/dashboard/services');
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error(_("NO_INTERNET"));
      }
    }

  }
   
  
  };

  const fetchRecords = async () => {
    try {
      let resp = await getServiceById(id);
      let obj = resp.data?.responseData;
      if (obj?.Providers[0]?.name !== null) {
        setShowProperty(true);
      }
      setValue('name', obj?.name);
      setValue('providername', obj?.Providers[0]?.name);
      setValue('key1', obj?.Providers[0]?.config?.key1);
      setValue('key2', obj?.Providers[0]?.config?.key2);
      setValue('textTranslation', obj?.Providers[0]?.config?.textTranslation);
      setValue('documentTranslation', obj?.Providers[0]?.config?.documentTranslation);

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    if(id){
    fetchRecords()
    }
  }, [])

  const onTypeChange = (val) => {
    if(val !== undefined || null){
    setShowProperty(true)
    }else{
      setShowProperty(false)
    }
  }


  

  return (
    <div className="page-content">
      <div className="container-fluid">
        
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              {id ? <h3 className="mb-sm-0 page-title">{_('EDIT_SERVICE')}</h3> : (
              <h3 className="mb-sm-0 page-title">{_('ADD_SERVICE')}</h3>
              )}
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to={"/dashboard/services/"}>{_('SERVICES')}</Link></li>
                    {id ?  <li className="breadcrumb-item active">{_('EDIT')}</li> : (
                    <li className="breadcrumb-item active">{_('ADD')}</li>)}
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <h4 className="card-title">{_('SERVICES')}</h4>
                <p className="card-title-desc">{_('SERVICE_INFO')}</p>
                
                <form onSubmit={handleSubmit(onSubmit)}>
                  
                  <div className="row">
                    <div className="mb-3">
                      <label className="form-label">{_('TITLE')}</label>
                      <Inputfield
                        control={control}
                        name={"name"}
                        placeholder={_('TITLE')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('TITLE_REQUIRED') },
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                        <label className="form-label">{_('SERVICE_PROVIDER')}</label>
                        <ReactSelectField
                          control={control}
                          name="providername"
                          label={_('SERVICE_PROVIDER')}
                          placeholder={_('SERVICE_PROVIDER')}
                          isClearable={true}
                          options={SERVICE_PROVIDERS}
                          optionValue="value"
                          optionLabel="label"
                          onSelect={onTypeChange}
                          rules={{
                            required: {
                              value: true,
                              message: _("SERVICE_PROVIDER_REQUIRED"),
                            },
                          }}
                          prefixClass="cstm_select"
                          />
                      </div>
                 
                 {showProperty && (

                  <div className="row mt-4">
                    <div className="mb-3">
                      <label className="form-label">{_('KEY1')}</label>
                      <Inputfield
                        control={control}
                        name={"key1"}
                        placeholder={_('KEY1')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('KEY_REQUIRED') },
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">{_('KEY2')}</label>
                      <Inputfield
                        control={control}
                        name={"key2"}
                        placeholder={_('KEY2')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('KEY_REQUIRED') },
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">{_('TEXT_TRANSLATION')}</label>
                      <Inputfield
                        control={control}
                        name={"textTranslation"}
                        placeholder={_('TEXT_TRANSLATION')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('TRANSLATION_LINK_REQUIRED') },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">{_('DOCUMENT_TRANSLATION')}</label>
                      <Inputfield
                        control={control}
                        name={"documentTranslation"}
                        placeholder={_('DOCUMENT_TRANSLATION')}
                        normalize={(e) => e.replace(/^\s+/g, "")}
                        type="text"
                        inputClassName={"form-control"}
                        rules={{
                          required: { value: true, message: _('DOCUMENT_TRANSLATION_REQUIRED') },
                        }}
                      />
                    </div>

                    <div className="mb-3">
                    <SingleCheckBox
                  control={control}
                  type="checkbox"
                  className="form-check-input"
                  id="isDefault"
                  name="isDefault"
                  labelClass="form-check-label"
                  
                  label={
                    <>
                    { _('IS_DEFAULT')}
                    </>
                  }
                />
                    </div>
                  </div>
)}

                  <div className="d-flex flex-wrap gap-2 action-btn-row">
                    <button type="submit" disabled={isSubmitting} className={`btn btn-primary waves-effect btn-save waves-light ${isSubmitting && "btn-loader"}`}>
                      {_('SAVE')}
                    </button>
                    <Link to={"/dashboard/services/"} className="btn btn-secondary btn-cancel waves-effect">
                      {_('CANCEL')}
                    </Link>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddService;
